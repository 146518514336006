import React from 'react';
import "./header.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faChartBar, faCog, faCalendarDay } from '@fortawesome/free-solid-svg-icons'

export class HeaderContainer extends React.Component {
	render() {
		return (
		<div id="header-container">
			<div id="information" className="header-icon" onClick={() => this.props.openHowToPlayModal()}>
				<FontAwesomeIcon icon={faQuestionCircle} />
			</div>
			<div id="yesterday" className="header-icon" onClick={() => this.props.openPreviousDayAnswerModal()}>
				<FontAwesomeIcon icon={faCalendarDay} />
			</div>
			<div id="title">
				FOURWORD
			</div>
			<div id="stats" className="header-icon" onClick={() => this.props.openStatsModal()}>
				<FontAwesomeIcon icon={faChartBar} />
			</div>
			<div id="settings" className="header-icon" onClick={() => this.props.openSettingsModal()}>
				<FontAwesomeIcon icon={faCog} />
			</div>
		</div>
		)
	}
}