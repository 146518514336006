import React from 'react';
import "./words.css";

export class GoalWordContainer extends React.Component {
	render() {
		return (
			<div id="goal-container">
				{this.props.word.split("").map((letter, key) => 
				<LetterContainer 
					letter={letter}
					key={key}
					isSelected={false}
					// extraClassNames={(!this.props.gameIsLive && this.props.letterMapping) ? " " + this.props.letterMapping[letter] : ""}
					extraClassNames={(!this.props.gameIsLive && this.props.indexMapping) ? " " + this.props.indexMapping[key] : ""}
					onClick={() => {}}
					/>)}
			</div>
		);
	}
}

export class WordContainer extends React.Component {
	getLeftOfLetterConnector() {
		if (this.props.changedLetterIndex < 0) {
			return "0px";
		} else {
			// half a letter plus and some extra plus one letter and two paddings per letter
			return (((1.25 / 2) + (0.3 / 2)) + ((1.25 + 0.2) * this.props.changedLetterIndex)) + "em"
		}
	}

	render() {
		var fullClassName = "word-container " + 
												this.props.similarityToGoalClass + 
												(this.props.wordIsActive ? " focused" : "") + 
												(this.props.fadeWordsIn ? " fade-in" : "");
		return (
			<div className={fullClassName}>
				<span 
					className="letter-connector" 
					style={{
						left: this.getLeftOfLetterConnector(),
						visibility: this.props.changedLetterIndex >= 0 ? "visible" : "hidden"
				}}></span>
				<div className={(this.props.isShaking ? " shaking" : "")}>
					{this.props.word.split("").map((letter, key) => 
						<LetterContainer 
							letter={letter}
							key={key}
							isSelected={this.props.gameIsLive && this.props.wordIsActive && this.props.selectedLetterIndex === key}
							// extraClassNames={(!this.props.gameIsLive && this.props.changedLetterIndex === key) ? " " + this.props.letterMapping[letter] : ""}
							extraClassNames={(!this.props.gameIsLive && this.props.changedLetterIndex === key && this.props.colorChangedLetters) ? " " + this.props.indexMapping[key] : ""}
							onClick={() => {
								this.props.onClick(key);
							}}
						/>
					)}
				</div>
			</div>
		);
	}
}

class LetterContainer extends React.Component {
	render() {
		var fullClassName = "letter" + 
												(this.props.isSelected ? " selected" : " not-selected") +
												(this.props.extraClassNames ? this.props.extraClassNames : "");
		return (
			<div
				className={fullClassName}
				onClick={this.props.onClick}>
				<span className="letter-text">{this.props.letter.toUpperCase()}</span>
			</div>
		)
	}
}