/*jshint loopfunc: true */
// eslint-disable-line no-loop-func

import { getCurrentDictionary } from './dictionary';
import { PriorityQueue } from './priority_queue';

var lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";

var optimalPathColorMapping = {
	"red": "🟥",
	"yellow": "🟨",
	"green": "🟩",
}

var shareableSolutionColorMapping = {
	// "red": "🟥",
	"orange": "🟧​​​",
	// "yellow": "🟨",
	// "green": "🟩",
	"blue": "🟦​",
	"purple": "🟪​​",
	// "black": "⬛️",
	// "white": "⬜️​", 
	"brown": "🟫​"
}

export function isAlphabetic(keyEvent) {
  return (lowercaseLetters + lowercaseLetters.toUpperCase()).includes(keyEvent)
}

export function isDirection(keyEvent) {
	return keyEvent === "ArrowLeft" || keyEvent === "ArrowRight" || keyEvent === "ArrowDown";
}

export function isValidWord(newWord, oldWord) {
  if (newWord === oldWord) {
    return false;
  }
  
  return getCurrentDictionary().includes(newWord);
}

export function getChangedLetterIndex(wordLadder, key) {
  if (key === wordLadder.length - 1) {
    return -1; //no changed letter
  } else {
    var curWord = wordLadder[key];
    var nextWord = wordLadder[key + 1];
    for (var i = 0; i < curWord.length; i++) {
      if (curWord.charAt(i) !== nextWord.charAt(i)) {
        return i
      }
    }
  }
}

function getChangedLetters(wordLadder) {
	var letters = [];
	for (var i = wordLadder.length - 2; i >= 0; i--) {
		var prevWord = wordLadder[i + 1];
		var curWord = wordLadder[i];
		for (var j = 0; j < curWord.length; j++) {
      if (curWord.charAt(j) !== prevWord.charAt(j)) {
        letters.push(curWord.charAt(j));
      }
    }
	}
	return letters;
}

function getChangedIndices(wordLadder) {
	var indices = [];
	for (var i = wordLadder.length - 2; i >= 0; i--) {
		var prevWord = wordLadder[i + 1];
		var curWord = wordLadder[i];
		for (var j = 0; j < curWord.length; j++) {
      if (curWord.charAt(j) !== prevWord.charAt(j)) {
        indices.push(j);
      }
    }
	}
	return indices;
}

function getDateAsString() {
	var today = new Date();
	return [today.getMonth(), today.getDate(), today.getFullYear()].join("/")
}

//ensures that each letter in the goal word has a unique color for maximum prettiness
function getLetterMappingFromRngAndGoalWord(rng, goalWord) {
	var letterMapping = {};
	lowercaseLetters.split("").forEach(letter => {
		letterMapping[letter] = Object.keys(shareableSolutionColorMapping)[Math.floor(rng() * Object.keys(shareableSolutionColorMapping).length)]
	});
	var shareableSolutionColorMappingKeys = Object.keys(shareableSolutionColorMapping);
	(new Set(goalWord.split(""))).forEach(letter => {
		var index = Math.floor(rng() * shareableSolutionColorMappingKeys.length)
		letterMapping[letter] = shareableSolutionColorMappingKeys[index];
		shareableSolutionColorMappingKeys.splice(index, 1);
	});
	return letterMapping;
}

//ensures that each column has a unique color for maximum prettiness
function getIndexMappingFromRngAndWordLength(rng, wordLength) {
	var indexMapping = {};
	var shareableSolutionColorMappingKeys = Object.keys(shareableSolutionColorMapping);
	(new Set([...Array(wordLength)].map((_, i) => i))).forEach(columnIndex => {
		var index = Math.floor(rng() * shareableSolutionColorMappingKeys.length)
		indexMapping[columnIndex] = shareableSolutionColorMappingKeys[index];
		shareableSolutionColorMappingKeys.splice(index, 1);
	});
	return indexMapping;
}


export function getDailyLetterMapping(goalWord) {
	var seedrandom = require('seedrandom');
	var rng = seedrandom(getDateAsString());
	return getLetterMappingFromRngAndGoalWord(rng, goalWord);
}

export function getDailyIndexMapping(wordLength) {
	var seedrandom = require('seedrandom');
	var rng = seedrandom(getDateAsString());
	return getIndexMappingFromRngAndWordLength(rng, wordLength);
}

export function getShareableSolutionFromLetters(wordLadder, letterMapping) {
	var changedLetters = getChangedLetters(wordLadder);

	return changedLetters.map(letter => letterMapping[letter]).map(color => {
		return shareableSolutionColorMapping[color];
	}).join("");
}

export function getShareableSolutionFromColumns(wordLadder, indexMapping) {
	var changedIndices = getChangedIndices(wordLadder);

	return changedIndices.map(index => indexMapping[index]).map(color => {
		return shareableSolutionColorMapping[color];
	}).join("");
}

export function getSimilarityToGoalClass(word, goalWord) {
	var epsilon = 0.01
	var similarityDecimal = getSimilarityToGoalDecimal(word, goalWord);
	if (similarityDecimal <= 0 + epsilon) {
		return "no-similarity";
	} else if (similarityDecimal <= 0.25 + epsilon) {
		return "small-similarity";
	} else if (similarityDecimal <= 0.5 + epsilon) {
		return "medium-similarity";
	} else if (similarityDecimal <= 0.75 + epsilon) {
		return "large-similarity";
	} else {
		return "full-similarity";
	}
}

function getSimilarityToGoalDecimal(word, goalWord) {
	if (word.length !== goalWord.length) {
		throw Error("word lengths did not match when checking their similarities");
	}

	if (word.length <= 0) {
		throw Error("word length must be positive");
	}

	var matchCounter = 0;
	for (var i = 0; i < word.length; i++) {
		if (word.charAt(i) === goalWord.charAt(i)) {
			matchCounter += 1;
		}
	}
	return matchCounter / word.length;
}

function getHammingDistance(word_one, word_two) {
	if (word_one.length !== word_two.length) {
		console.log("illegal input, words are different lengths");
	} else if (word_one.length === 0) {
		console.log("illegal input, words are length zero");
	}

	var counter = 0;
	for (var i = 0; i < word_one.length; i++) {
		if (word_one[i] !== word_two[i]) {
			counter += 1;
		}
	}
	return counter;
}

function getHeuristic(end_word) {
	return function heuristic(start_word) {
		return getHammingDistance(start_word, end_word)
	}
}

function reconstructPath(came_from, current) {
	var total_path = [current];
	while (Object.keys(came_from).includes(current)) {
		current = came_from[current];
		total_path.unshift(current);
	}
	return total_path;
}

// function getNeighbors(word) {
// 	var all_neighbors = [];
// 	for (var i = 0; i < word.length; i++) {
// 		lowercaseLetters.split("").forEach(new_letter => {
// 			var temp_word = word.substring(0, i) + new_letter + word.substring(i + 1, word.length)
// 			if (temp_word !== word && getCurrentDictionary().includes(temp_word)) {
// 				all_neighbors.push(temp_word);
// 			}
// 		});
// 	}
// 	return all_neighbors;
// }

function getNeighbors(word) {
	var all_neighbors = [];
	[...Array(5)].map((_, i) => i).forEach(i => {
		lowercaseLetters.split("").forEach(new_letter => {
			var temp_word = word.substring(0, i) + new_letter + word.substring(i + 1, word.length)
			if (temp_word !== word && getCurrentDictionary().includes(temp_word)) {
				all_neighbors.push(temp_word);
			}
		});
	})

	// for (var i = 0; i < word.length; i++) {
		
	// }
	return all_neighbors;
}

var aStarCache = {}

export function aStar(start, goal) {
	if (aStarCache[start+goal]) {
		return aStarCache[start+goal]
	}

	var h = getHeuristic(goal);
	var pq = new PriorityQueue();
	var came_from = {};
	var gScore = {};
	gScore[start] = 0;
	var fScore = {};
	fScore[start] = h(start);
	var current, current_value, tentative_gScore;
	var default_value = Number.MAX_SAFE_INTEGER - 100;

	pq.push([fScore[start], start]);
	var visited = new Set();

	while (!pq.isEmpty()) {
		current = pq.pop();
		current_value = current[1];
		visited.add(current_value);

		if (current_value === goal) {
			const reconstructedPath = reconstructPath(came_from, current_value);
			aStarCache[start+goal] = reconstructedPath;
			return reconstructedPath;
		}

		var neighbors = getNeighbors(current_value);
		for (var i = 0; i < neighbors.length; i++) {
			var neighbor = neighbors[i];
			tentative_gScore = (gScore[current_value] ?? default_value) + 1 // neighbors have distance 1
			if (tentative_gScore < (gScore[neighbor] ?? default_value)) {
				came_from[neighbor] = current_value
        gScore[neighbor] = tentative_gScore
				fScore[neighbor] = tentative_gScore + h(neighbor)
				if (!visited.has(neighbor)) {
					pq.push([(fScore[neighbor] ?? default_value), neighbor]);
				}
			}
		}
	}

	return "FAILURE"
}

export async function getOptimalPathShareableSolution(wordLadder, goalWord) {
	var solutionList = [];
	var oldDistance = aStar(wordLadder[wordLadder.length - 1], goalWord).length - 1;
	for (var i = wordLadder.length - 2; i >= 0; i--) {
		var newDistance = aStar(wordLadder[i], goalWord).length - 1;
		if (newDistance < oldDistance) {
			solutionList.push("green"); 
		} else if (newDistance === oldDistance) {
			solutionList.push("yellow");
		} else if (newDistance > oldDistance) {
			solutionList.push("red");
		} else {
			throw Error("you messed up in getOptimalPathShareableSolution, coder");
		}
		oldDistance = newDistance;
	}
	return solutionList.map((color) => optimalPathColorMapping[color]).join("");
}

export function getDayIndex() {
	var today = new Date();
	var inception_date = new Date("01/24/2022");

	var difference_in_time = today.getTime() - inception_date.getTime();
		
	// To calculate the no. of days between two dates
	return Math.floor(difference_in_time / (1000 * 3600 * 24));
}