import React from 'react';
import Cookies from 'universal-cookie';
import "./game.css";

import {HeaderContainer} from '../header/header'
import {WordContainer, GoalWordContainer} from '../words/words'
import {isValidWord, isAlphabetic, isDirection, getChangedLetterIndex, getSimilarityToGoalClass, getDailyLetterMapping, getDailyIndexMapping, aStar, getOptimalPathShareableSolution, getDayIndex} from '../utils/utils'
import {StatsGenerator} from '../utils/stats_generator'
import {Keyboard} from '../keyboard/keyboard'
import {HowToPlayModal} from '../modals/how_to_play_modal'
import {StatsModal} from '../modals/stats_modal'
import {SettingsModal} from '../modals/settings_modal'
import {PreviousDayAnswerModal} from '../modals/previous_day_answer_modal'
import {CopiedToast, NoTakebacksToast, RestartGameToast} from '../modals/toasts'
import {InstructionsPopUps} from '../instructions_pop_ups/instructions_pop_ups'

var HOW_TO_MODAL_STARTS_ON = true;
var INSTRUCTION_POP_UPS_OFF = false;

//initializes cookies and sets their expiration date as far in the future as possible
const cookies = new Cookies();
var expireDate = new Date();
var expireTime = expireDate.getTime() + 10000000*36000;
expireDate.setTime(expireTime);
var cookieOptions = {expires: expireDate}

export class Game extends React.Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();

		//resets the word ladder every day
		if (cookies.get("startingWord") !== this.props.startingWord || cookies.get("goalWord") !== this.props.goalWord) {
			cookies.remove("wordLadder");
			cookies.set("startingWord", this.props.startingWord, cookieOptions);
			cookies.set("goalWord", this.props.goalWord, cookieOptions);
			cookies.set("isSetToSwitchGoalAndStart", "false", cookieOptions);
			cookies.set("gameIsLive", "true", cookieOptions);

			cookies.set("minimumSolveLength", aStar(this.props.goalWord, this.props.startingWord).length - 1, cookieOptions);
		}

		//cookies for testing
		// cookies.set("isSetToSwitchGoalAndStart", "true", cookieOptions);
		// cookies.set("settingsModalIsVisible", "true", cookieOptions);

		// open the stats modal if the game is over
		if (cookies.get("gameIsLive") === "false") {
			cookies.set("statsModalIsVisible", "true", cookieOptions);
		} else {
			cookies.set("statsModalIsVisible", "false", cookieOptions);
		}

		// set the cookies that need to exist
		cookies.set("wordLadder", cookies.get("wordLadder") ?? [this.props.startingWord], cookieOptions);
		cookies.set("minimumSolveLength", cookies.get("minimumSolveLength") ?? aStar(this.props.goalWord, this.props.startingWord).length - 1, cookieOptions);

		this.state = {
			wordLadder: cookies.get("wordLadder"),
			wordLength: this.props.startingWord.length,
			gameIsLive: cookies.get("gameIsLive") === "false" ? false : true,
			isSetToHardMode: cookies.get("isSetToHardMode") === "true" ? true : false,
			isSetToSwitchGoalAndStart: cookies.get("isSetToSwitchGoalAndStart") === "true" ? true : false,
			selectedLetterIndex: cookies.get("selectedLetterIndex") ?? -1,
			howToPlayModalIsVisible: cookies.get("hasSeenInstructions") === "true" ? false : HOW_TO_MODAL_STARTS_ON,
			statsModalIsVisible: cookies.get("statsModalIsVisible") === "true" ? true : false,
			settingsModalIsVisible: cookies.get("settingsModalIsVisible") === "true" ? true : false,
			previousDayAnswerModalIsVisible: cookies.get("previousDayAnswerModalIsVisible") === "true" ? true : false,
			focusedWordIsShaking: false,
			dailyLetterMapping: getDailyLetterMapping(this.props.goalWord),
			dailyIndexMapping: getDailyIndexMapping(this.props.startingWord.length),
			copiedToastIsVisible: false,
			noTakebacksToastIsVisible: false,
			restartGameToastIsVisible: false,
			minimumSolveLength: parseInt(cookies.get("minimumSolveLength")),
			optimalPathShareableSolution: cookies.get("optimalPathShareableSolution") ?? getOptimalPathShareableSolution(cookies.get("wordLadder"), ((cookies.get("isSetToSwitchGoalAndStart") === "true" ? true : false) ? this.props.startingWord : this.props.goalWord)),
			statsGenerator: cookies.get("statsGeneratorString") ? new StatsGenerator(cookies.get("statsGeneratorString")) : new StatsGenerator("")
		}
	}

	// an active click is a click in the box of the first word under the goal word
	handleActiveClick(i) {
		this.setState({
			selectedLetterIndex: i
		})
	}

	// an inactive click is a click in any box below the first word under the goal word
	handleInactiveClick(i) {
		if (!this.state.isSetToHardMode && i < this.state.wordLadder.length) {
			cookies.set("wordLadder", this.state.wordLadder.slice(i), cookieOptions);
			this.setState({
				wordLadder: this.state.wordLadder.slice(i)
			})
		} else {
			this.openNoTakebacksToast();
		}
	}

	handleKeyboardClick(i) {
		if (i === "down") {
			this.eventKeyHandler("ArrowDown");
		} else {
			this.eventKeyHandler(i);
		}
	}

	handleNewWord(newWord) {
		// Add new word
		this.state.wordLadder.unshift(newWord);
		cookies.set("wordLadder", this.state.wordLadder, cookieOptions)
		cookies.set("hasSeenInstructions", "true", cookieOptions)

		this.forceUpdate();

		// Update the optimalSolutionCode
		getOptimalPathShareableSolution(this.state.wordLadder, this.getCurrentGoalWord()).then(solution => {
			this.setState({
				optimalPathShareableSolution: solution
			}, () => {
				cookies.set("optimalPathShareableSolution", this.state.optimalPathShareableSolution, cookieOptions);
				// console.log("optimalPathShareableSolution: " + this.state.optimalPathShareableSolution)
			});
		}).catch(error => {
			console.log("getOptimalPathShareableSolution errors with: " + error);
			return "ERROR";
		});

		// Check if new word is the goal word - check if game is won - win
		if (newWord === this.getCurrentGoalWord()) {
			if (!this.props.isEasterEggGame) {
				this.state.statsGenerator.addSolution(getDayIndex(), this.state.minimumSolveLength, this.state.wordLadder.length - 1);
				cookies.set("statsGeneratorString", this.state.statsGenerator.getStatsString(), cookieOptions);
			}
			
			this.setState({
				gameIsLive: false
			}, () => {
				cookies.set("gameIsLive", "false", cookieOptions);
				this.openStatsModal();
			})
		}
	}

	eventKeyHandler = (eventKey) => {
		var activeWord = this.state.wordLadder[0];
		if (this.state.gameIsLive) {
			if (isDirection(eventKey)) {
				if (eventKey === "ArrowLeft") {
					this.setState({
						selectedLetterIndex: Math.max(this.state.selectedLetterIndex - 1, 0)
					});
				} else if (eventKey === "ArrowRight") {
					this.setState({
						selectedLetterIndex: (this.state.selectedLetterIndex === -1 ? this.state.wordLength - 1 : Math.min(this.state.selectedLetterIndex + 1, this.state.wordLength - 1))
					});
				} else if (eventKey === "ArrowDown") {
					this.handleInactiveClick(1); //same action as clicking the highest inactive word
				} else {
					throw Error("key event " + eventKey + " has not been implemented");
				}
			} else if (isAlphabetic(eventKey)) {
				if (this.state.selectedLetterIndex >= 0 && this.state.selectedLetterIndex < this.state.wordLength) {
					var newLetter = eventKey.toLowerCase();
					var newWord = activeWord.substr(0, this.state.selectedLetterIndex) 
											+ newLetter
											+ activeWord.substr(this.state.selectedLetterIndex + 1, this.state.wordLength);
					if (isValidWord(newWord, activeWord)) {
						this.handleNewWord(newWord)
					} else {
						this.turnOnShaking();
					}
				}
			}
		}
	}

	keydownHandler = (event) => {
		this.eventKeyHandler(event.key)
	}

	openHowToPlayModal() {
		// cookies.set("hasSeenInstructions", "", cookieOptions);
		this.setState({
			howToPlayModalIsVisible: true
		}, () => {
			if (this.state.wordLadder.length === 1) {
				cookies.set("hasSeenInstructions", "false", cookieOptions);
			}
		})
	}

	closeHowToPlayModal() {
		// cookies.set("hasSeenInstructions", "true", cookieOptions);
		this.setState({
			howToPlayModalIsVisible: false
		})
	}

	openStatsModal() {
		cookies.set("statsModalIsVisible", "true", cookieOptions);
		this.setState({
			statsModalIsVisible: true
		})
	}

	closeStatsModal() {
		cookies.set("statsModalIsVisible", "false", cookieOptions);
		this.setState({
			statsModalIsVisible: false
		})
	}

	openSettingsModal() {
		cookies.set("settingsModalIsVisible", "true", cookieOptions);
		this.setState({
			settingsModalIsVisible: true
		})
	}

	closeSettingsModal() {
		cookies.set("settingsModalIsVisible", "false", cookieOptions);
		this.setState({
			settingsModalIsVisible: false
		})
	}

	openPreviousDayAnswerModal() {
		cookies.set("previousDayAnswerModalIsVisible", "true", cookieOptions);
		this.setState({
			previousDayAnswerModalIsVisible: true
		})
	}

	closePreviousDayAnswerModal() {
		cookies.set("previousDayAnswerModalIsVisible", "false", cookieOptions);
		this.setState({
			previousDayAnswerModalIsVisible: false
		})
	}

	openCopiedToast() {
		this.turnOnCopiedToast();
	}

	openNoTakebacksToast() {
		this.turnOnNoTakebacksToast();
	}

	openRestartGameToast() {
		this.turnOnRestartGameToast();
	}

	//shaking is for when the user tries to input an invalid word
	turnOnShaking() {
		this.setState({focusedWordIsShaking: true}, () => {
			//turn off shaking after half a second
			setTimeout(() => {
				this.turnOffShaking()
			}, 400);
		});
	}

	turnOffShaking() {
		this.setState({focusedWordIsShaking: false});
	}

	//copied toast is for when a user copies their code after completing the game
	turnOnCopiedToast() {
		this.setState({copiedToastIsVisible: true});

		//turn off copied toast after half a second
		setTimeout(() => {
			this.turnOffCopiedToast()
		}, 500);
	}

	turnOffCopiedToast() {
		this.setState({copiedToastIsVisible: false});
	}

	//no takebacks toast is for users playing on hard mode who try to take a move back
	turnOnNoTakebacksToast() {
		this.setState({noTakebacksToastIsVisible: true});

		//turn off no takebacks toast after half a second
		setTimeout(() => {
			this.turnOffNoTakebacksToast()
		}, 500);
	}

	turnOffNoTakebacksToast() {
		this.setState({noTakebacksToastIsVisible: false});
	}

	//restart game toast is for when a user restarts the game
	turnOnRestartGameToast() {
		this.setState({restartGameToastIsVisible: true});

		//turn off restart game toast after half a second
		setTimeout(() => {
			this.turnOffRestartGameToast()
		}, 500);
	}

	turnOffRestartGameToast() {
		this.setState({restartGameToastIsVisible: false});
	}

	toggleHardMode() {
		if (this.state.isSetToHardMode) {
			this.setState({
				isSetToHardMode: false
			}, () => {
				cookies.set("isSetToHardMode", "false", cookieOptions);
			})
		} else {
			this.setState({
				isSetToHardMode: true
			}, () => {
				cookies.set("isSetToHardMode", "true", cookieOptions);
			})
		}
	}

	toggleSwitchGoalAndStartWords() {
		if (this.state.isSetToSwitchGoalAndStart) {
			this.setState({
				isSetToSwitchGoalAndStart: false,
				wordLadder: [this.props.startingWord]
			}, () => {
				cookies.set("isSetToSwitchGoalAndStart", "false", cookieOptions);
				cookies.set("wordLadder", this.state.wordLadder, cookieOptions);
			})
		} else {
			this.setState({
				isSetToSwitchGoalAndStart: true,
				wordLadder: [this.props.goalWord]
			}, () => {
				cookies.set("isSetToSwitchGoalAndStart", "true", cookieOptions);
				cookies.set("wordLadder", this.state.wordLadder, cookieOptions);
			})
		}
	}

	closeAllModals() {
		this.closeHowToPlayModal();
		this.closeStatsModal();
		this.closeSettingsModal();
		this.closePreviousDayAnswerModal();
	}

	restartGame() {
		this.closeAllModals();
		this.openRestartGameToast();

		this.setState({
			gameIsLive: true,
			wordLadder: this.state.wordLadder.slice(this.state.wordLadder.length - 1),
			selectedLetterIndex: -1
		}, () => {
			cookies.set("gameIsLive", "true", cookieOptions)
			cookies.set("wordLadder", this.state.wordLadder, cookieOptions)
			cookies.set("selectedLetterIndex", this.state.selectedLetterIndex, cookieOptions)
			cookies.set("optimalPathShareableSolution", "", cookieOptions)
		})
	}

	getCurrentGoalWord() {
		return this.state.isSetToSwitchGoalAndStart ? this.props.startingWord : this.props.goalWord;
	}

	getCurrentStartWord() {
		return this.state.isSetToSwitchGoalAndStart ? this.props.goalWord : this.props.startingWord;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keydownHandler);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keydownHandler);
	}

	render() {
		return (
			<div id="game-container" ref={this.wrapper}>
				<HeaderContainer
					gameIsLive={this.state.gameIsLive}
					openHowToPlayModal={() => this.openHowToPlayModal()}
					openPreviousDayAnswerModal={() => this.openPreviousDayAnswerModal()}
					openStatsModal={() => this.openStatsModal()}
					openSettingsModal={() => this.openSettingsModal()}
					/>
				<GoalWordContainer 
					word={this.getCurrentGoalWord()}
					gameIsLive={this.state.gameIsLive}
					letterMapping={this.state.dailyLetterMapping}
					indexMapping={this.state.dailyIndexMapping}
				/>
				<div className="section-separator"></div>
				<span id="word-ladder" className={this.state.gameIsLive ? "extra-bottom-margin" : ""}>
					{this.state.wordLadder.map((word, index) => 
						<WordContainer
							word={word} 
							similarityToGoalClass={getSimilarityToGoalClass(word, this.getCurrentGoalWord())}
							wordIsActive={index === 0}
							gameIsLive={this.state.gameIsLive}
							changedLetterIndex={getChangedLetterIndex(this.state.wordLadder, index)}
							selectedLetterIndex={this.state.selectedLetterIndex}
							onClick={this.state.gameIsLive ? (index === 0 ? i => this.handleActiveClick(i) : () => this.handleInactiveClick(index)) : () => {}}
							onNewWord={newWord => this.handleNewWord(newWord)}
							isShaking={index === 0 && this.state.focusedWordIsShaking}
							letterMapping={this.state.dailyLetterMapping}
							indexMapping={this.state.dailyIndexMapping}
							colorChangedLetters={true}
							fadeWordsIn={true}
							key={this.state.wordLadder.length - 1 - index}
						/>
					)}
					{INSTRUCTION_POP_UPS_OFF || cookies.get("hasSeenInstructions") === "true" ? "" : 
						<InstructionsPopUps 
							selectedLetterIndex = {this.state.selectedLetterIndex}
						/>
					}
				</span>
				
				{this.state.gameIsLive ? 
					<Keyboard
						isDownButtonVisible={!this.state.isSetToHardMode && this.state.wordLadder.length > 1}
						onClick={this.state.gameIsLive ? i => this.handleKeyboardClick(i) : () => {}}
					/> : ""
				}
				{this.state.howToPlayModalIsVisible ?
					<HowToPlayModal
						startingWord={this.getCurrentStartWord()}
						goalWord={this.getCurrentGoalWord()}
						closeModal={() => this.closeHowToPlayModal()}
					/> : ""
				}
				{this.state.statsModalIsVisible ?
					<StatsModal
						wordLadder={this.state.wordLadder}
						minimumSolveLength={this.state.minimumSolveLength}
						optimalPathShareableSolution={this.state.optimalPathShareableSolution.toString()}
						indexMapping={this.state.dailyIndexMapping}
						dailyWordIndex={this.props.dailyWordIndex}
						gameIsLive={this.state.gameIsLive}
						isSetToHardMode={this.state.isSetToHardMode}
						closeModal={() => this.closeStatsModal()}
						openCopiedToast={() => this.openCopiedToast()}
						restartGame={() => this.restartGame()}
						showMinimumSolve={true}
						completedValue={this.state.statsGenerator.getCompleted()}
						optimalPercentageValue={this.state.statsGenerator.getOptimalPercentage()}
						currentStreakValue={this.state.statsGenerator.getCurrentStreak()}
						currentOptimalStreakValue={this.state.statsGenerator.getCurrentOptimalStreak()}
						maxStreakValue={this.state.statsGenerator.getMaxStreak()}
						maxOptimalStreakValue={this.state.statsGenerator.getMaxOptimalStreak()}
						numberOfSolutionsTodayValue={this.state.statsGenerator.getNumberOfSolutionsToday()}
						totalSolutionsValue={this.state.statsGenerator.getTotalSolutions()}
						isEasterEggGame={this.props.isEasterEggGame}
					/> : ""
				}
				{this.state.settingsModalIsVisible ?
					<SettingsModal
						gameIsLive={this.state.gameIsLive}
						isSetToHardMode={this.state.isSetToHardMode}
						toggleHardMode={() => this.toggleHardMode()}
						isSetToSwitchGoalAndStart={this.state.isSetToSwitchGoalAndStart}
						toggleSwitchGoalAndStartWords={() => this.toggleSwitchGoalAndStartWords()}
						restartGame={() => this.restartGame()}
						closeModal={() => this.closeSettingsModal()}
					/> : ""
				}
				{this.state.previousDayAnswerModalIsVisible ?
					<PreviousDayAnswerModal
						prevDayStartWord={this.props.prevDayStartWord}
						prevDayEndWord={this.props.prevDayEndWord}
						closeModal={() => this.closePreviousDayAnswerModal()}
					/> : ""
				}
				{this.state.copiedToastIsVisible ?
					<CopiedToast /> : ""
				}
				{this.state.noTakebacksToastIsVisible ?
					<NoTakebacksToast /> : ""
				}
				{this.state.restartGameToastIsVisible ?
					<RestartGameToast /> : ""
				}
			</div>
		)
	}
}