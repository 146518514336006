import React from 'react';
import "./instructions_pop_ups.css";

import click_a_letter from '../../images/click_a_letter.png'; // Tell webpack this JS file uses this image
import choose_a_new_letter from '../../images/choose_a_new_letter.png'; // Tell webpack this JS file uses this image

export class InstructionsPopUps extends React.Component {
  render() {
    return (
      <div className="instructions-pop-ups">
        <div id="first-instructions" className={this.props.selectedLetterIndex === -1 ? "bouncing" : "faded"}>
          <img src={click_a_letter} alt="Instructions that say to click a letter that you want to change"></img>
        </div>
        {this.props.selectedLetterIndex === -1 ? "" :
          <div id="second-instructions" className="bouncing">
            <img src={choose_a_new_letter} alt="Instructions that say to choose a new letter, creating a word"></img>
          </div>
        }
      </div>
    );
  }
}
