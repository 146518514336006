import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import FourwordDictionary from './app_components/fourword_dictionary/fourword_dictionary'
import FourwordBuilder from './app_components/fourword_builder/fourword_builder'
import reportWebVitals from './reportWebVitals';

var last_nineteen_chars = window.location.href.substr(window.location.href.length - 19);
var last_seven_chars = window.location.href.substr(window.location.href.length - 7);
ReactDOM.render(
  <React.StrictMode>
    {(last_nineteen_chars === "fourword-dictionary") ? <FourwordDictionary /> : 
     (last_seven_chars === "builder") ? <FourwordBuilder /> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
