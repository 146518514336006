import React from 'react';
import "./modals.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faList, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function openWordList() {
  window.open("\\#fourword-dictionary", '_blank');
}

function sendFeedbackEmail() {
  window.open("mailto:fourword.feedback@gmail.com?subject=Fourword Feedback", '_blank')
}

function openInstagram() {
  window.open("https://www.instagram.com/fourword.xyz/", '_blank')
}

export class SettingsModal extends React.Component {
  render() {
    return (
      <div className="modal-container">
        <div className="modal-background" onClick={() => this.props.closeModal()}></div>
        <div className="modal-text">
          <span className="modal-exit" onClick={() => this.props.closeModal()}>&times;</span>
          <h3>Settings</h3>
          <SettingWithToggle 
            mainText={"Hard Mode"}
            secondaryText={"No takebacks allowed"}
            isClicked={this.props.isSetToHardMode}
            disabledWhenGameIsOver={true}
            gameIsLive={this.props.gameIsLive}
            onClick={() => {
              return this.props.toggleHardMode();
            }}
          />
          <SettingWithToggle 
            mainText={"Switch Goal and Start Words"}
            secondaryText={"Warning: this erases all your progress!"}
            isClicked={this.props.isSetToSwitchGoalAndStart}
            disabledWhenGameIsOver={true}
            gameIsLive={this.props.gameIsLive}
            onClick={() => {
              return this.props.toggleSwitchGoalAndStartWords();
            }}
          />
          <SettingWithIcon 
            mainText={"Restart Game"}
            secondaryText={"Try again for a better score, or to find a new solution"}
            icon={faUndo}
            onClick={() => {
              return this.props.restartGame();
            }}
          />
          <SettingWithIcon 
            mainText={"Word List"}
            secondaryText={"View the list of words that Fourword considers valid"}
            icon={faList}
            onClick={() => {
              return openWordList();
            }}
          />
          <SettingWithIcon 
            mainText={"Feedback"}
            icon={faEnvelope}
            onClick={() => {
              return sendFeedbackEmail();
            }}
          />
          <SettingWithIcon 
            mainText={"Find us on Instagram"}
            icon={faInstagram}
            onClick={() => {
              return openInstagram();
            }}
          />
        </div>
      </div>
    );
  }
}

class SettingBase extends React.Component {
  render() {
    return (
      <span className="setting-base">
        <div className="setting-container">
          <div className="left-side">
            {this.props.leftSide}
          </div>
          <div className="right-side">
            {this.props.rightSide}
          </div>
        </div>
        <div className="modal-section-separator"></div>
      </span>
    );
  }
}

class SettingWithToggle extends React.Component {
  render() {
    return (
      <SettingBase
        leftSide = {
          <div>
            <div className="main-text">{this.props.mainText}</div>
            <div className="secondary-text">{this.props.secondaryText}</div>
          </div>
        }
        rightSide = {
          <div className="toggle-button">
            <label className="switch">
              <input type="checkbox" defaultChecked={this.props.isClicked} disabled={this.props.disabledWhenGameIsOver && !this.props.gameIsLive} onClick={() => this.props.onClick()}></input>
              <span className="slider round"></span>
            </label>
          </div>
        }
      />
    );
  }
}

class SettingWithIcon extends React.Component {
  render() {
    return (
      <SettingBase
        leftSide = {
          <div>
            <div className="main-text">{this.props.mainText}</div>
            <div className="secondary-text">{this.props.secondaryText}</div>
          </div>
        }
        rightSide = {
          <div className="icon-button" onClick={() => this.props.onClick()}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
        }
      />
    );
  }
}