/*
Stats to track:
completed, optimal percentage, current streak, optimal streak,
max streak, max optimal streak

stats to not track:
unique solutions today, max unique solutions in a day,

STATS COOKIE STRING
date_as_index:optimal_solve_length:solution_1_length&solution_2_length&solution_3_length\n
date_as_index:optimal_solve_length:solution_1_length&solution_2_length&solution_3_length\n
date_as_index:optimal_solve_length:solution_1_length&solution_2_length&solution_3_length\n
date_as_index:optimal_solve_length:solution_1_length&solution_2_length&solution_3_length\n
*/

// const testCookieString = "\
// 2:5:5&6&7\n \
// 3:5:5\n \
// 4:5:6&7\n \
// 5:5:7\n \
// 8:5:5&6&7\n \
// 9:5:5&6&7\n \
// 10:5:5&6&7\n \
// "

import {getDayIndex} from "../utils/utils";

export class StatsGenerator {
  constructor(statsCookieString) {
    if (statsCookieString === "") {
      this.daysSolved = [];
    } else {
      this.daysSolved = statsCookieString.trim().split("\n");
    }

    this.updateStats()
  }

  updateStats() {
    this.completedDays = this.daysSolved.length;

    var optimalDays = 0;
    var consecutiveOptimalDays = 0;
    var maxConsecutiveOptimalDays = 0;
    var consecutiveDays = 0;
    var maxConsecutiveDays = 0;
    var prevOptimalDay = "";
    var prevDay = "";
    var numberOfSolutionsToday = 0;
    var totalSolutions = 0;

    this.daysSolved.forEach(day => {
      var solutionComponents = day.split(":");
      var dateAsIndex = parseInt(solutionComponents[0]);
      var optimalSolutionLength = parseInt(solutionComponents[1]);
      var solutions = solutionComponents[2].split("&").map(numberAsString => parseInt(numberAsString));
      var usersBestSolution = Math.min(...solutions)

      if (dateAsIndex === getDayIndex()) {
        numberOfSolutionsToday = solutions.length;
      }

      totalSolutions += solutions.length;

      // if day before was optimal, increment optimal stuff
      if (usersBestSolution === optimalSolutionLength) {
        optimalDays += 1;
        if (prevOptimalDay === dateAsIndex - 1) {
          consecutiveOptimalDays += 1;
        } else {
          consecutiveOptimalDays = 1
        }
        prevOptimalDay = dateAsIndex;
      } else {
        consecutiveOptimalDays = 0
      }

      // increment standard stuff
      if (prevDay === dateAsIndex - 1) {
        consecutiveDays += 1;
      } else {
        consecutiveDays = 1;
      }
      prevDay = dateAsIndex;
      
      //update max stuff
      if (maxConsecutiveOptimalDays < consecutiveOptimalDays) {
        maxConsecutiveOptimalDays = consecutiveOptimalDays;
      }
      if (maxConsecutiveDays < consecutiveDays) {
        maxConsecutiveDays = consecutiveDays;
      }
    });

    this.optimalDays = optimalDays;
    this.consecutiveOptimalDays = consecutiveOptimalDays;
    this.maxConsecutiveOptimalDays = maxConsecutiveOptimalDays;
    this.consecutiveDays = consecutiveDays;
    this.maxConsecutiveDays = maxConsecutiveDays;
    this.numberOfSolutionsToday = numberOfSolutionsToday;
    this.totalSolutions = totalSolutions;
  }

  addSolution(dateAsIndex, optimalSolutionLength, userSolutionLength) {
    if (this.daysSolved.length === 0) {
      this.daysSolved.push(dateAsIndex + ":" + optimalSolutionLength + ":" + userSolutionLength);
    } else {
      var mostRecentEntry = this.daysSolved[this.daysSolved.length - 1].split(":");
      var mostRecentDate = parseInt(mostRecentEntry[0]);
      if (mostRecentDate === dateAsIndex) {
        // add solution to end of most recent entry
        this.daysSolved[this.daysSolved.length - 1] = this.daysSolved[this.daysSolved.length - 1] + "&" + userSolutionLength;
      } else {
        // add solution as new entry
        this.daysSolved.push(dateAsIndex + ":" + optimalSolutionLength + ":" + userSolutionLength);
      }
    }

    this.updateStats()
  }

  getStatsString() {
    return this.daysSolved.join("\n");
  }

  getCompleted() {
    return this.completedDays;
  }

  getOptimalPercentage() {
    return this.completedDays === 0 ? 0 : this.optimalDays / this.completedDays;
  }

  getCurrentStreak() {
    return this.consecutiveDays;
  }

  getCurrentOptimalStreak() {
    return this.consecutiveOptimalDays;
  }

  getMaxStreak() {
    return this.maxConsecutiveDays;
  }

  getMaxOptimalStreak() {
    return this.maxConsecutiveOptimalDays;
  }

  getNumberOfSolutionsToday() {
    return this.numberOfSolutionsToday;
  }

  getTotalSolutions() {
    return this.totalSolutions;
  }
}