// import logo from './logo.svg';
import './fourword_dictionary.css';
import React from 'react';

import { getCurrentDictionary } from '../utils/dictionary';

function FourwordDictionary() {

  return (
    <div className="App">
      <header className="App-header with-padding">
        <h2 className="fourword-dictionary-header">Fourword's List of Valid Words</h2>
        <p>If there's a word you believe should be added to the list, please email <a className="link-text" href="mailto:fourword.feedback@gmail.com?subject=Fourword Feedback">fourword.feedback@gmail.com</a></p>
        <p>To return to the game, click <a className="link-text" href="\">here</a></p>
        <p className="all-dictionary-words">{getCurrentDictionary().join(", ")}</p>
      </header>
    </div>
  );
}

export default FourwordDictionary;
