// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';

import {Game} from './app_components/game/game';
import {word_combos_i_like} from './app_components/utils/dictionary';

var EASTER_EGG = true;
export var EASTER_EGG_IS_LIVE = false;
var SADE_LIAM_EASTER_EGG_TURNED_ON = true;
export var SADE_LIAM_EASTER_EGG_IS_LIVE = false;
var ASHWIN_EASTER_EGG_TURNED_ON = true;
export var ASHWIN_EASTER_EGG_IS_LIVE = false;

function App() {

  function getDayIndex() {
    var today = new Date();
    var inception_date = new Date("01/24/2022");
  
    var difference_in_time = today.getTime() - inception_date.getTime();
      
    // To calculate the no. of days between two dates
    return Math.floor(difference_in_time / (1000 * 3600 * 24));
  }
  
  function getWordsFromDayIndex(index) {
    return word_combos_i_like[index % word_combos_i_like.length]
  }

  var todayIndex = getDayIndex();
  var todays_words = getWordsFromDayIndex(todayIndex);
  var yeserdays_words = getWordsFromDayIndex(todayIndex-1);
  
  if (EASTER_EGG) {
    var last_nine_chars = window.location.href.substr(window.location.href.length - 9);

    if (last_nine_chars.includes("-")) {
      var todays_words_unchecked = last_nine_chars.split("-");
      if (todays_words_unchecked.length === 2 && todays_words_unchecked[0].length === 4 && todays_words_unchecked[1].length === 4) {
        todays_words = todays_words_unchecked;
      }
      EASTER_EGG_IS_LIVE = true;
    } else {
      EASTER_EGG_IS_LIVE = false; //not sure if this is needed but want to be safe
    }
  }
  
  var last_seven_chars = window.location.href.substr(window.location.href.length - 7);

  if (SADE_LIAM_EASTER_EGG_TURNED_ON) {
    if (last_seven_chars.includes("mystery")) {
      todays_words = ["sade", "liam"];
      SADE_LIAM_EASTER_EGG_IS_LIVE = true;
    } else {
      SADE_LIAM_EASTER_EGG_IS_LIVE = false; //not sure if this is needed but want to be safe
    }
  } else {
    SADE_LIAM_EASTER_EGG_IS_LIVE = false; //not sure if this is needed but want to be safe
  }

  if (ASHWIN_EASTER_EGG_TURNED_ON) {
    if (last_seven_chars.includes("compass")) {
      todays_words = ["fire", "jams"];
      ASHWIN_EASTER_EGG_IS_LIVE = true;
    } else {
      ASHWIN_EASTER_EGG_IS_LIVE = false; //not sure if this is needed but want to be safe
    }
  } else {
    ASHWIN_EASTER_EGG_IS_LIVE = false; //not sure if this is needed but want to be safe
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <Game 
          startingWord={todays_words[0]} 
          goalWord={todays_words[1]}
          dailyWordIndex={todayIndex}
          prevDayStartWord={yeserdays_words[0]}
          prevDayEndWord={yeserdays_words[1]}
          isEasterEggGame={EASTER_EGG_IS_LIVE || SADE_LIAM_EASTER_EGG_IS_LIVE || ASHWIN_EASTER_EGG_IS_LIVE}
        />
        {/* <Game startingWord="soil" goalWord="tree"/> */}
      </header>
    </div>
  );
}

export default App;
