import React from 'react';
import "./modals.css";

export class CopiedToast extends React.Component {
  render() {
    return (
      <div id="copied-toast" className="toast">
        Copied!
      </div>
    )
  }
}

export class NoTakebacksToast extends React.Component {
  render() {
    return (
      <div id="no-takebacks-toast" className="toast">
        No Takebacks on Hard Mode!
      </div>
    )
  }
}

export class RestartGameToast extends React.Component {
  render() {
    return (
      <div id="restart-game-toast" className="toast">
        Game Restarted
      </div>
    )
  }
}