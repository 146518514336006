import React from 'react';
import "./modals.css";

import { WordContainer } from '../words/words'
import { getSimilarityToGoalClass, aStar, getChangedLetterIndex } from '../utils/utils'

export class PreviousDayAnswerModal extends React.Component {
  optimal_path = aStar(this.props.prevDayStartWord, this.props.prevDayEndWord);

  render() {
    return (
      <div className="modal-container">
        <div className="modal-background" onClick={() => this.props.closeModal()}></div>
        <div className="modal-text previous_day_answer_text">
          <span className="modal-exit" onClick={() => this.props.closeModal()}>&times;</span>
          <h3>Yesterday's Solution</h3>
          {this.optimal_path.map((curWord, index) => 
            <WordContainer 
              word={curWord} 
              similarityToGoalClass={getSimilarityToGoalClass(curWord, this.props.prevDayEndWord)}
              wordIsActive={false}
              gameIsLive={false}
              // changedLetterIndex={-1}
              changedLetterIndex={getChangedLetterIndex(this.optimal_path, index)}
              selectedLetterIndex={-1}
              onClick={() => {}}
              onNewWord={() => {}}
              key={index}
            />
          )}
        </div>
      </div>
    );
  }
}