import {SADE_LIAM_EASTER_EGG_IS_LIVE} from '../../App'
// var SADE_LIAM_EASTER_EGG_IS_LIVE = false;
// console.log(SADE_LIAM_EASTER_EGG ? sade_liam_dictionary : normal_dictionary);

var normal_dictionary = [
	"abed",
	"abet",
	"able",
	"ably",
	"abut",
	"acai",
	"aced",
	"aces",
	"ache",
	"achy",
	"acid",
	"acme",
	"acne",
	"acre",
	"acts",
	"adds",
	"adit",
	"adze",
	"aeon",
	"afar",
	"afro",
	"agar",
	"aged",
	"ages",
	"agog",
	"ague",
	"ahem",
	"aide",
	"aids",
	"ails",
	"aims",
	"airs",
	"airy",
	"ajar",
	"akin",
	"alar",
	"alas",
	"ales",
	"alga",
	"ally",
	"alms",
	"aloe",
	"also",
	"alto",
	"alum",
	"ambo",
	"amen",
	"amid",
	"ammo",
	"amok",
	"amps",
	"amyl",
	"ands",
	"anew",
	"ankh",
	"anna",
	"anon",
	"anti",
	"ants",
	"aped",
	"apes",
	"apex",
	"apps",
	"apse",
	"aqua",
	"arch",
	"arcs",
	"area",
	"aria",
	"arid",
	"aril",
	"arks",
	"arms",
	"army",
	"arse",
	"arts",
	"arty",
	"arum",
	"aryl",
	"ashy",
	"asks",
	"atom",
	"atop",
	"aunt",
	"aura",
	"auto",
	"aver",
	"avid",
	"avow",
	"away",
	"awed",
	"awes",
	"awls",
	"awry",
	"axed",
	"axel",
	"axes",
	"axil",
	"axis",
	"axle",
	"axon",
	"baal",
	"baas",
	"baba",
	"babe",
	"baby",
	"bach",
	"back",
	"bade",
	"bads",
	"bags",
	"baht",
	"bail",
	"bait",
	"bake",
	"bald",
	"bale",
	"balk",
	"ball",
	"balm",
	"band",
	"bane",
	"bang",
	"bank",
	"bans",
	"barb",
	"bard",
	"bare",
	"barf",
	"bark",
	"barn",
	"bars",
	"base",
	"bash",
	"bask",
	"bass",
	"bast",
	"bath",
	"bats",
	"batt",
	"baud",
	"bawl",
	"bays",
	"bead",
	"beak",
	"beam",
	"bean",
	"bear",
	"beat",
	"beau",
	"beck",
	"beds",
	"beef",
	"been",
	"beep",
	"beer",
	"bees",
	"beet",
	"begs",
	"bell",
	"bels",
	"belt",
	"bend",
	"bent",
	"berg",
	"berm",
	"best",
	"beta",
	"bets",
	"bevy",
	"beys",
	"bias",
	"bibb",
	"bibs",
	"bide",
	"bids",
	"bier",
	"biff",
	"bike",
	"bile",
	"bilk",
	"bill",
	"bind",
	"bins",
	"bint",
	"bios",
	"bird",
	"bite",
	"bits",
	"blab",
	"blah",
	"blam",
	"blat",
	"bleb",
	"bled",
	"blew",
	"blip",
	"blob",
	"bloc",
	"blog",
	"blot",
	"blow",
	"blue",
	"blur",
	"boar",
	"boas",
	"boat",
	"bobs",
	"bode",
	"bods",
	"body",
	"bogs",
	"bogy",
	"boil",
	"bola",
	"bold",
	"bole",
	"boll",
	"bolo",
	"bolt",
	"bomb",
	"bond",
	"bone",
	"bong",
	"bonk",
	"bony",
	"boob",
	"book",
	"boom",
	"boon",
	"boop",
	"boor",
	"boos",
	"boot",
	"bops",
	"bore",
	"born",
	"boss",
	"both",
	"bots",
	"bout",
	"bowl",
	"bows",
	"boxy",
	"boyo",
	"boys",
	"bozo",
	"brad",
	"brag",
	"bran",
	"bras",
	"brat",
	"bray",
	"bred",
	"brew",
	"brie",
	"brig",
	"brim",
	"brio",
	"bris",
	"brit",
	"bros",
	"brow",
	"brut",
	"buck",
	"buds",
	"buff",
	"bugs",
	"bulb",
	"bulk",
	"bull",
	"bump",
	"bums",
	"bund",
	"bung",
	"bunk",
	"buns",
	"bunt",
	"buoy",
	"burb",
	"burg",
	"burl",
	"burn",
	"burp",
	"burr",
	"bury",
	"bush",
	"busk",
	"buss",
	"bust",
	"busy",
	"butt",
	"buys",
	"buzz",
	"byes",
	"byre",
	"byte",
	"cabs",
	"cads",
	"cafe",
	"caff",
	"cage",
	"cake",
	"calf",
	"call",
	"calm",
	"came",
	"cami",
	"camo",
	"camp",
	"cams",
	"cane",
	"cans",
	"cape",
	"capo",
	"caps",
	"carb",
	"card",
	"care",
	"carp",
	"carr",
	"cars",
	"cart",
	"case",
	"cash",
	"cask",
	"cast",
	"cats",
	"caul",
	"cava",
	"cave",
	"cavy",
	"caws",
	"cays",
	"cede",
	"cell",
	"cels",
	"celt",
	"cent",
	"cess",
	"chad",
	"chai",
	"chap",
	"char",
	"chat",
	"chef",
	"chew",
	"chic",
	"chin",
	"chip",
	"chit",
	"chop",
	"chow",
	"chub",
	"chug",
	"chum",
	"ciao",
	"cite",
	"city",
	"clad",
	"clam",
	"clan",
	"clap",
	"claw",
	"clay",
	"clef",
	"clip",
	"clod",
	"clog",
	"clop",
	"clot",
	"cloy",
	"club",
	"clue",
	"coal",
	"coat",
	"coax",
	"cobs",
	"coca",
	"cock",
	"coco",
	"coda",
	"code",
	"cods",
	"coed",
	"cogs",
	"coho",
	"coif",
	"coil",
	"coin",
	"coir",
	"coke",
	"cola",
	"cold",
	"cole",
	"colt",
	"coma",
	"comb",
	"come",
	"comp",
	"cone",
	"conk",
	"conn",
	"cons",
	"cook",
	"cool",
	"coop",
	"coos",
	"coot",
	"cope",
	"cops",
	"copy",
	"cord",
	"core",
	"cork",
	"corm",
	"corn",
	"cosh",
	"cost",
	"cosy",
	"cote",
	"cots",
	"coup",
	"cove",
	"cowl",
	"cows",
	"cozy",
	"crab",
	"crag",
	"cram",
	"crap",
	"craw",
	"crew",
	"crib",
	"crit",
	"croc",
	"crop",
	"crow",
	"crud",
	"crus",
	"crux",
	"cube",
	"cubs",
	"cuds",
	"cued",
	"cues",
	"cuff",
	"cull",
	"culm",
	"cult",
	"cups",
	"curb",
	"curd",
	"cure",
	"curl",
	"curs",
	"curt",
	"cusp",
	"cuss",
	"cute",
	"cuts",
	"cyan",
	"cyst",
	"czar",
	"dabs",
	"dace",
	"dada",
	"dado",
	"dads",
	"daft",
	"dais",
	"dale",
	"dame",
	"damn",
	"damp",
	"dams",
	"dank",
	"dare",
	"dark",
	"darn",
	"dart",
	"dash",
	"data",
	"date",
	"daub",
	"dawn",
	"days",
	"daze",
	"dead",
	"deaf",
	"deal",
	"dean",
	"dear",
	"debt",
	"deck",
	"deed",
	"deem",
	"deep",
	"deer",
	"deft",
	"defy",
	"deke",
	"deli",
	"dell",
	"delt",
	"demo",
	"dens",
	"dent",
	"deny",
	"derm",
	"desk",
	"dews",
	"dewy",
	"dhal",
	"dhow",
	"dial",
	"dice",
	"died",
	"dies",
	"diet",
	"digs",
	"dike",
	"dill",
	"dime",
	"dims",
	"dine",
	"ding",
	"dink",
	"dino",
	"dint",
	"dips",
	"dire",
	"dirk",
	"dirt",
	"disc",
	"dish",
	"disk",
	"diss",
	"diva",
	"dive",
	"dock",
	"docs",
	"dodo",
	"doer",
	"does",
	"doff",
	"doge",
	"dogs",
	"dojo",
	"dole",
	"doll",
	"dolt",
	"dome",
	"done",
	"dons",
	"dong",
	"doom",
	"door",
	"dope",
	"dork",
	"dorm",
	"dory",
	"dosa",
	"dose",
	"dote",
	"doth",
	"dots",
	"dour",
	"dove",
	"down",
	"doxy",
	"doze",
	"dozy",
	"drab",
	"drag",
	"dram",
	"drat",
	"draw",
	"dray",
	"drew",
	"drip",
	"drop",
	"drub",
	"drug",
	"drum",
	"drys",
	"dual",
	"dubs",
	"duck",
	"duct",
	"dude",
	"duds",
	"duel",
	"dues",
	"duet",
	"duff",
	"dugs",
	"duke",
	"dull",
	"duly",
	"dumb",
	"dump",
	"dune",
	"dung",
	"dunk",
	"duns",
	"duos",
	"dupe",
	"dura",
	"dusk",
	"dust",
	"duty",
	"dyad",
	"dyed",
	"dyer",
	"dyes",
	"dyke",
	"each",
	"earl",
	"earn",
	"ears",
	"ease",
	"east",
	"easy",
	"eats",
	"eave",
	"ebbs",
	"echo",
	"ecru",
	"eddy",
	"edge",
	"edgy",
	"edit",
	"eels",
	"eggs",
	"eggy",
	"egos",
	"ekes",
	"elan",
	"elks",
	"ells",
	"elms",
	"else",
	"emic",
	"emir",
	"emit",
	"emmy",
	"emus",
	"ends",
	"envy",
	"eons",
	"epee",
	"epic",
	"eras",
	"ergs",
	"errs",
	"eruv",
	"etas",
	"etch",
	"euro",
	"even",
	"ever",
	"eves",
	"evil",
	"ewer",
	"ewes",
	"exam",
	"exec",
	"exit",
	"exon",
	"expo",
	"eyed",
	"eyes",
	"fabs",
	"face",
	"fact",
	"fade",
	"fado",
	"fads",
	"fail",
	"fain",
	"fair",
	"fake",
	"fall",
	"fame",
	"fang",
	"fans",
	"fare",
	"farm",
	"faro",
	"fart",
	"fast",
	"fate",
	"fats",
	"faun",
	"fava",
	"fave",
	"fawn",
	"faze",
	"fear",
	"feat",
	"feck",
	"feds",
	"feed",
	"feel",
	"fees",
	"feet",
	"fell",
	"felt",
	"fend",
	"fens",
	"fern",
	"fess",
	"fest",
	"feta",
	"fete",
	"feud",
	"fiat",
	"fibs",
	"fief",
	"fife",
	"figs",
	"file",
	"fill",
	"film",
	"filo",
	"fils",
	"find",
	"fine",
	"fink",
	"fins",
	"fire",
	"firm",
	"firs",
	"fish",
	"fist",
	"fits",
	"five",
	"fizz",
	"flab",
	"flag",
	"flak",
	"flam",
	"flan",
	"flap",
	"flat",
	"flaw",
	"flax",
	"flay",
	"flea",
	"fled",
	"flee",
	"flew",
	"flex",
	"flip",
	"flit",
	"floc",
	"floe",
	"flog",
	"flop",
	"flow",
	"flub",
	"flue",
	"flus",
	"flux",
	"foal",
	"foam",
	"fobs",
	"foci",
	"foes",
	"fogs",
	"fogy",
	"foil",
	"fold",
	"folk",
	"fond",
	"font",
	"food",
	"fool",
	"foot",
	"fops",
	"ford",
	"fore",
	"fork",
	"form",
	"fort",
	"foul",
	"four",
	"fowl",
	"foxy",
	"frag",
	"frat",
	"fray",
	"free",
	"fret",
	"frig",
	"frit",
	"froe",
	"frog",
	"from",
	"fuel",
	"fugu",
	"full",
	"fume",
	"fund",
	"funk",
	"furl",
	"furs",
	"fury",
	"fuse",
	"fuss",
	"futz",
	"fuzz",
	"gaff",
	"gage",
	"gags",
	"gain",
	"gait",
	"gala",
	"gale",
	"gall",
	"gals",
	"game",
	"gams",
	"gang",
	"gaol",
	"gape",
	"gaps",
	"garb",
	"gash",
	"gasp",
	"gate",
	"gave",
	"gawk",
	"gawp",
	"gays",
	"gaze",
	"gear",
	"geek",
	"gees",
	"geld",
	"gels",
	"gelt",
	"gems",
	"gene",
	"gens",
	"gent",
	"genu",
	"germ",
	"geta",
	"gets",
	"ghat",
	"ghee",
	"gibe",
	"gift",
	"gigs",
	"gild",
	"gill",
	"gilt",
	"gimp",
	"gins",
	"gird",
	"girl",
	"girt",
	"gist",
	"gite",
	"gits",
	"give",
	"glad",
	"glam",
	"glee",
	"glen",
	"glia",
	"glib",
	"glob",
	"glom",
	"glop",
	"glow",
	"glue",
	"glug",
	"glum",
	"glut",
	"gnar",
	"gnat",
	"gnaw",
	"gnus",
	"goad",
	"goal",
	"goat",
	"gobs",
	"goby",
	"gods",
	"goer",
	"goes",
	"gold",
	"golf",
	"gone",
	"gong",
	"good",
	"goof",
	"gook",
	"goon",
	"goop",
	"goos",
	"gore",
	"gory",
	"gosh",
	"goth",
	"gout",
	"gown",
	"goys",
	"grab",
	"grad",
	"gram",
	"gran",
	"gray",
	"gree",
	"grew",
	"grey",
	"grid",
	"grim",
	"grin",
	"grip",
	"grit",
	"grog",
	"grow",
	"grub",
	"grue",
	"guar",
	"guff",
	"gulf",
	"gull",
	"gulp",
	"gums",
	"gunk",
	"guns",
	"guru",
	"gush",
	"gust",
	"guts",
	"guys",
	"gyms",
	"gyre",
	"gyro",
	"hack",
	"haft",
	"hags",
	"hail",
	"hair",
	"hajj",
	"hake",
	"hale",
	"half",
	"hall",
	"halo",
	"halt",
	"hams",
	"hand",
	"hang",
	"hank",
	"haps",
	"hard",
	"hare",
	"hark",
	"harm",
	"harp",
	"hart",
	"hash",
	"hasp",
	"hate",
	"hath",
	"hats",
	"haul",
	"have",
	"hawk",
	"haws",
	"hays",
	"haze",
	"hazy",
	"head",
	"heal",
	"heap",
	"hear",
	"heat",
	"heck",
	"heed",
	"heel",
	"heft",
	"heir",
	"held",
	"hell",
	"helm",
	"helo",
	"help",
	"heme",
	"hemp",
	"hems",
	"hens",
	"herb",
	"herd",
	"here",
	"hero",
	"hers",
	"hewn",
	"hews",
	"hick",
	"hide",
	"hied",
	"high",
	"hike",
	"hill",
	"hilt",
	"hind",
	"hint",
	"hips",
	"hire",
	"hiss",
	"hits",
	"hive",
	"hiya",
	"hoar",
	"hoax",
	"hobo",
	"hock",
	"hoed",
	"hoes",
	"hogs",
	"hoke",
	"hold",
	"hole",
	"holo",
	"holt",
	"holy",
	"home",
	"hone",
	"honk",
	"hood",
	"hoof",
	"hook",
	"hoop",
	"hoot",
	"hope",
	"hops",
	"hora",
	"horn",
	"hose",
	"host",
	"hots",
	"hour",
	"hove",
	"howl",
	"hubs",
	"huck",
	"hued",
	"hues",
	"huff",
	"huge",
	"hugs",
	"hula",
	"hulk",
	"hull",
	"hump",
	"hums",
	"hung",
	"hunk",
	"hunt",
	"hurl",
	"hurt",
	"hush",
	"husk",
	"huts",
	"hymn",
	"hype",
	"hypo",
	"ibex",
	"ibis",
	"iced",
	"ices",
	"icky",
	"icon",
	"idea",
	"idle",
	"idly",
	"idol",
	"iffy",
	"ikat",
	"ikon",
	"ills",
	"imam",
	"imps",
	"inch",
	"info",
	"inks",
	"inky",
	"inns",
	"into",
	"ions",
	"iota",
	"ires",
	"iris",
	"irks",
	"iron",
	"isle",
	"isms",
	"itch",
	"item",
	"jabs",
	"jack",
	"jade",
	"jail",
	"jake",
	"jamb",
	"jams",
	"jape",
	"jars",
	"java",
	"jaws",
	"jays",
	"jazz",
	"jean",
	"jeep",
	"jeer",
	"jeez",
	"jefe",
	"jell",
	"jerk",
	"jest",
	"jets",
	"jibe",
	"jibs",
	"jigs",
	"jilt",
	"jink",
	"jinn",
	"jinx",
	"jive",
	"jobs",
	"jock",
	"joey",
	"jogs",
	"john",
	"join",
	"joke",
	"jolt",
	"josh",
	"jots",
	"jowl",
	"joys",
	"judo",
	"judy",
	"jugs",
	"juju",
	"juke",
	"jump",
	"junk",
	"jury",
	"just",
	"jute",
	"juts",
	"kaka",
	"kale",
	"kami",
	"kart",
	"kata",
	"kava",
	"keel",
	"keen",
	"keep",
	"kegs",
	"kelp",
	"kept",
	"kerb",
	"kerf",
	"keto",
	"keys",
	"khan",
	"khat",
	"kick",
	"kids",
	"kill",
	"kiln",
	"kilo",
	"kilt",
	"kind",
	"king",
	"kink",
	"kips",
	"kiss",
	"kite",
	"kith",
	"kits",
	"kiva",
	"kiwi",
	"knee",
	"knew",
	"knit",
	"knob",
	"knot",
	"know",
	"koan",
	"kohl",
	"koji",
	"kola",
	"kore",
	"kook",
	"kora",
	"koto",
	"kudu",
	"labs",
	"lace",
	"lack",
	"lacy",
	"lads",
	"lady",
	"lags",
	"laid",
	"lain",
	"lair",
	"lake",
	"lakh",
	"lama",
	"lamb",
	"lame",
	"lamp",
	"lams",
	"land",
	"lane",
	"laps",
	"lard",
	"lark",
	"lash",
	"lass",
	"last",
	"late",
	"lath",
	"lats",
	"laud",
	"lava",
	"lave",
	"lawn",
	"laws",
	"lays",
	"laze",
	"lazy",
	"lead",
	"leaf",
	"leak",
	"lean",
	"leap",
	"leas",
	"leek",
	"leer",
	"leet",
	"left",
	"legs",
	"leis",
	"lend",
	"lens",
	"lent",
	"less",
	"lest",
	"lets",
	"levy",
	"lewd",
	"leys",
	"liar",
	"libs",
	"lice",
	"lick",
	"lids",
	"lied",
	"lien",
	"lier",
	"lies",
	"lieu",
	"life",
	"lift",
	"like",
	"lilt",
	"lily",
	"lima",
	"limb",
	"lime",
	"limn",
	"limo",
	"limp",
	"line",
	"ling",
	"link",
	"lint",
	"lion",
	"lips",
	"lira",
	"lisp",
	"list",
	"lite",
	"live",
	"load",
	"loaf",
	"loam",
	"loan",
	"lobe",
	"lobs",
	"loch",
	"lock",
	"loco",
	"lode",
	"loft",
	"loge",
	"logo",
	"logs",
	"loin",
	"loll",
	"lone",
	"long",
	"look",
	"loom",
	"loon",
	"loop",
	"loos",
	"loot",
	"lope",
	"lops",
	"lord",
	"lore",
	"lose",
	"loss",
	"lost",
	"loth",
	"lots",
	"loud",
	"lout",
	"love",
	"lows",
	"luau",
	"lube",
	"luck",
	"luff",
	"luge",
	"lugs",
	"lull",
	"lump",
	"lune",
	"lung",
	"lure",
	"lurk",
	"lush",
	"lust",
	"lute",
	"luvs",
	"lynx",
	"lyre",
	"lyse",
	"maar",
	"mace",
	"mach",
	"mack",
	"macs",
	"made",
	"mage",
	"magi",
	"mags",
	"maid",
	"mail",
	"maim",
	"main",
	"make",
	"maki",
	"mako",
	"male",
	"mall",
	"malt",
	"mama",
	"mane",
	"mans",
	"many",
	"maps",
	"mare",
	"mark",
	"marl",
	"mars",
	"mart",
	"masa",
	"mash",
	"mask",
	"mass",
	"mast",
	"mate",
	"math",
	"mats",
	"maul",
	"maws",
	"maxi",
	"maya",
	"mayo",
	"maze",
	"mazy",
	"mead",
	"meal",
	"mean",
	"meat",
	"meek",
	"meet",
	"mega",
	"meld",
	"melt",
	"memo",
	"mend",
	"menu",
	"meow",
	"mere",
	"mesa",
	"mesh",
	"mess",
	"meta",
	"mete",
	"mewl",
	"mews",
	"meze",
	"mica",
	"mice",
	"mics",
	"mids",
	"mien",
	"mike",
	"mild",
	"mile",
	"milk",
	"mill",
	"milt",
	"mime",
	"mina",
	"mind",
	"mine",
	"mini",
	"mink",
	"mint",
	"minx",
	"mire",
	"miry",
	"mise",
	"miso",
	"miss",
	"mist",
	"mite",
	"mitt",
	"moan",
	"moat",
	"mobs",
	"mock",
	"mode",
	"mods",
	"mojo",
	"moke",
	"mola",
	"mold",
	"mole",
	"moll",
	"molt",
	"moms",
	"monk",
	"mono",
	"mood",
	"mook",
	"moon",
	"moor",
	"moos",
	"moot",
	"mope",
	"mops",
	"more",
	"morn",
	"mosh",
	"moss",
	"most",
	"mote",
	"moth",
	"moue",
	"move",
	"mown",
	"mows",
	"much",
	"muck",
	"muds",
	"muff",
	"mugs",
	"mule",
	"mull",
	"mums",
	"mung",
	"muon",
	"murk",
	"muse",
	"mush",
	"musk",
	"muss",
	"must",
	"mute",
	"mutt",
	"myth",
	"naan",
	"nabe",
	"nabs",
	"naff",
	"nags",
	"nail",
	"name",
	"nana",
	"nans",
	"napa",
	"nape",
	"naps",
	"narc",
	"nard",
	"nare",
	"nary",
	"nave",
	"navy",
	"nays",
	"neap",
	"near",
	"neat",
	"neck",
	"need",
	"neem",
	"nene",
	"neon",
	"nerd",
	"ness",
	"nest",
	"nets",
	"news",
	"newt",
	"next",
	"nibs",
	"nice",
	"nick",
	"nigh",
	"nine",
	"nips",
	"nite",
	"nobs",
	"nock",
	"node",
	"nods",
	"noel",
	"noir",
	"nome",
	"none",
	"nook",
	"noon",
	"nope",
	"nori",
	"norm",
	"nose",
	"nosh",
	"nosy",
	"note",
	"noun",
	"nous",
	"nova",
	"nubs",
	"nude",
	"nuke",
	"null",
	"numb",
	"nuns",
	"nuts",
	"oafs",
	"oaks",
	"oaky",
	"oars",
	"oath",
	"oats",
	"obey",
	"obit",
	"oboe",
	"odds",
	"odes",
	"odor",
	"ogee",
	"ogle",
	"ogre",
	"oils",
	"oily",
	"oink",
	"okay",
	"okes",
	"okra",
	"olds",
	"oldy",
	"oleo",
	"olla",
	"omen",
	"omit",
	"once",
	"ones",
	"only",
	"onto",
	"onus",
	"onyx",
	"oops",
	"ooze",
	"oozy",
	"opal",
	"open",
	"opts",
	"opus",
	"oral",
	"orbs",
	"orca",
	"ordo",
	"ores",
	"orgy",
	"oryx",
	"orzo",
	"otic",
	"otto",
	"ouch",
	"ours",
	"oust",
	"outs",
	"ouzo",
	"oval",
	"oven",
	"over",
	"ovum",
	"owed",
	"owes",
	"owls",
	"owns",
	"pace",
	"pack",
	"pact",
	"pads",
	"page",
	"paid",
	"pail",
	"pain",
	"pair",
	"pale",
	"pall",
	"palm",
	"palp",
	"pals",
	"pane",
	"pang",
	"pans",
	"pant",
	"papa",
	"paps",
	"para",
	"pare",
	"park",
	"pars",
	"part",
	"pass",
	"past",
	"pate",
	"path",
	"pats",
	"pave",
	"pawn",
	"paws",
	"pays",
	"peak",
	"peal",
	"pear",
	"peas",
	"peat",
	"peck",
	"pecs",
	"peds",
	"peed",
	"peek",
	"peel",
	"peep",
	"peer",
	"pees",
	"pegs",
	"pelt",
	"pens",
	"pent",
	"peon",
	"pepo",
	"pere",
	"peri",
	"perk",
	"perm",
	"perp",
	"pert",
	"perv",
	"peso",
	"pest",
	"pets",
	"pews",
	"phew",
	"phis",
	"pica",
	"pice",
	"pick",
	"pics",
	"pied",
	"pier",
	"pies",
	"pigs",
	"pika",
	"pike",
	"pile",
	"pill",
	"pimp",
	"pine",
	"ping",
	"pink",
	"pins",
	"pint",
	"pion",
	"pipa",
	"pipe",
	"pish",
	"piss",
	"pita",
	"pith",
	"pits",
	"pity",
	"pixy",
	"plan",
	"plat",
	"play",
	"plea",
	"pleb",
	"pled",
	"plex",
	"plod",
	"plop",
	"plot",
	"plow",
	"ploy",
	"plug",
	"plum",
	"plus",
	"pock",
	"pods",
	"poem",
	"poet",
	"pogo",
	"poke",
	"poky",
	"pole",
	"poll",
	"polo",
	"pols",
	"poly",
	"pome",
	"pomp",
	"pond",
	"pone",
	"pong",
	"pony",
	"poof",
	"pooh",
	"pool",
	"poop",
	"poor",
	"poos",
	"pope",
	"pops",
	"pore",
	"pork",
	"porn",
	"port",
	"pose",
	"posh",
	"post",
	"posy",
	"pots",
	"pouf",
	"pour",
	"pout",
	"poxy",
	"pram",
	"prat",
	"pray",
	"prep",
	"prey",
	"prez",
	"prig",
	"prim",
	"prob",
	"prod",
	"prof",
	"prog",
	"prom",
	"prop",
	"pros",
	"prow",
	"psis",
	"pubs",
	"puck",
	"puds",
	"puff",
	"pugs",
	"puja",
	"puke",
	"pull",
	"pulp",
	"puma",
	"pump",
	"punk",
	"puns",
	"punt",
	"puny",
	"pupa",
	"pups",
	"pure",
	"puri",
	"purl",
	"purr",
	"push",
	"puss",
	"puts",
	"putt",
	"putz",
	"pyre",
	"pyro",
	"quad",
	"quay",
	"quid",
	"quip",
	"quit",
	"quiz",
	"race",
	"rack",
	"racy",
	"rads",
	"raft",
	"raga",
	"rage",
	"rags",
	"raid",
	"rail",
	"rain",
	"raja",
	"rake",
	"raki",
	"raku",
	"rale",
	"rami",
	"ramp",
	"rams",
	"rang",
	"rank",
	"rant",
	"rape",
	"raps",
	"rapt",
	"rare",
	"rash",
	"rasp",
	"rate",
	"rath",
	"rats",
	"rave",
	"rays",
	"raze",
	"razz",
	"read",
	"real",
	"ream",
	"reap",
	"rear",
	"redo",
	"reds",
	"reed",
	"reef",
	"reek",
	"reel",
	"refs",
	"rein",
	"rely",
	"rems",
	"rend",
	"rent",
	"repo",
	"repp",
	"reps",
	"rest",
	"rete",
	"rhea",
	"ribs",
	"rice",
	"rich",
	"rick",
	"ride",
	"rids",
	"rife",
	"riff",
	"rift",
	"rigs",
	"rile",
	"rill",
	"rime",
	"rims",
	"rind",
	"ring",
	"rink",
	"riot",
	"ripe",
	"rips",
	"rise",
	"risk",
	"rite",
	"ritz",
	"road",
	"roam",
	"roan",
	"roar",
	"robe",
	"robs",
	"rock",
	"rode",
	"rods",
	"roes",
	"roil",
	"role",
	"roll",
	"romp",
	"roms",
	"rood",
	"roof",
	"rook",
	"room",
	"roos",
	"root",
	"rope",
	"ropy",
	"rose",
	"rosy",
	"rota",
	"rote",
	"roti",
	"roto",
	"rots",
	"rout",
	"roux",
	"rove",
	"rows",
	"rube",
	"rubs",
	"ruby",
	"ruck",
	"rude",
	"rued",
	"rues",
	"ruff",
	"rugs",
	"ruin",
	"rule",
	"rump",
	"rums",
	"rune",
	"rung",
	"runs",
	"runt",
	"ruse",
	"rush",
	"rust",
	"ruth",
	"ruts",
	"ryes",
	"sack",
	"sacs",
	"safe",
	"saga",
	"sage",
	"sago",
	"sags",
	"said",
	"sail",
	"sake",
	"saki",
	"sale",
	"salt",
	"same",
	"sand",
	"sane",
	"sang",
	"sank",
	"sans",
	"saps",
	"sari",
	"sash",
	"sass",
	"sate",
	"sati",
	"save",
	"sawn",
	"saws",
	"says",
	"scab",
	"scad",
	"scam",
	"scan",
	"scar",
	"scat",
	"scot",
	"scow",
	"scud",
	"scum",
	"seal",
	"seam",
	"sear",
	"seas",
	"seat",
	"sect",
	"seed",
	"seek",
	"seem",
	"seen",
	"seep",
	"seer",
	"sees",
	"self",
	"sell",
	"semi",
	"send",
	"sent",
	"sept",
	"sere",
	"serf",
	"seta",
	"sets",
	"sett",
	"sewn",
	"sews",
	"sexy",
	"shad",
	"shag",
	"shah",
	"sham",
	"shed",
	"shew",
	"shim",
	"shin",
	"ship",
	"shiv",
	"shod",
	"shoe",
	"shoo",
	"shop",
	"shot",
	"show",
	"shul",
	"shun",
	"shut",
	"sibs",
	"sick",
	"side",
	"sift",
	"sigh",
	"sign",
	"sika",
	"silk",
	"sill",
	"silo",
	"silt",
	"sims",
	"sine",
	"sing",
	"sink",
	"sins",
	"sips",
	"sire",
	"sirs",
	"site",
	"sits",
	"size",
	"skeg",
	"skew",
	"skid",
	"skim",
	"skin",
	"skip",
	"skis",
	"skit",
	"skua",
	"slab",
	"slag",
	"slam",
	"slap",
	"slat",
	"slaw",
	"slay",
	"sled",
	"slew",
	"slid",
	"slim",
	"slip",
	"slit",
	"slob",
	"sloe",
	"slog",
	"slop",
	"slot",
	"slow",
	"slug",
	"slum",
	"slur",
	"smog",
	"smug",
	"smut",
	"snag",
	"snap",
	"snip",
	"snit",
	"snob",
	"snog",
	"snot",
	"snow",
	"snub",
	"snug",
	"soak",
	"soap",
	"soar",
	"soba",
	"sobs",
	"soca",
	"sock",
	"soda",
	"sods",
	"sofa",
	"soft",
	"soil",
	"soke",
	"sold",
	"sole",
	"solo",
	"soma",
	"some",
	"song",
	"sons",
	"soon",
	"soot",
	"sops",
	"sora",
	"sore",
	"sort",
	"sots",
	"souk",
	"soul",
	"soup",
	"sour",
	"sown",
	"sows",
	"soya",
	"spam",
	"span",
	"spar",
	"spas",
	"spat",
	"spay",
	"spaz",
	"spec",
	"sped",
	"spew",
	"spin",
	"spit",
	"spot",
	"spry",
	"spud",
	"spun",
	"spur",
	"stab",
	"stag",
	"star",
	"stat",
	"stay",
	"stem",
	"step",
	"stew",
	"stir",
	"stop",
	"stow",
	"stub",
	"stud",
	"stun",
	"stye",
	"subs",
	"such",
	"suck",
	"sued",
	"sues",
	"suet",
	"suit",
	"sulk",
	"sumo",
	"sump",
	"sums",
	"sung",
	"sunk",
	"suns",
	"sups",
	"sura",
	"sure",
	"surf",
	"suss",
	"swab",
	"swag",
	"swam",
	"swan",
	"swap",
	"swat",
	"sway",
	"swig",
	"swim",
	"swum",
	"sync",
	"tabs",
	"tabu",
	"tach",
	"tack",
	"taco",
	"tact",
	"tags",
	"tail",
	"take",
	"talc",
	"tale",
	"talk",
	"tall",
	"tame",
	"tamp",
	"tang",
	"tank",
	"tans",
	"tapa",
	"tape",
	"taps",
	"tare",
	"tarn",
	"taro",
	"tarp",
	"tars",
	"tart",
	"task",
	"taut",
	"taxa",
	"taxi",
	"teak",
	"teal",
	"team",
	"tear",
	"teas",
	"teat",
	"tech",
	"teem",
	"teen",
	"tees",
	"teff",
	"tele",
	"tell",
	"temp",
	"tend",
	"tens",
	"tent",
	"term",
	"tern",
	"test",
	"text",
	"than",
	"that",
	"thaw",
	"thee",
	"them",
	"then",
	"thew",
	"they",
	"thin",
	"this",
	"thou",
	"thro",
	"thru",
	"thud",
	"thug",
	"thus",
	"tian",
	"tick",
	"tics",
	"tide",
	"tidy",
	"tied",
	"tier",
	"ties",
	"tiff",
	"tiki",
	"tile",
	"till",
	"tilt",
	"time",
	"tine",
	"ting",
	"tins",
	"tint",
	"tiny",
	"tipi",
	"tips",
	"tire",
	"toad",
	"toby",
	"toed",
	"toes",
	"toff",
	"tofu",
	"toga",
	"toil",
	"toke",
	"told",
	"tole",
	"toll",
	"tomb",
	"tome",
	"tone",
	"tong",
	"tons",
	"tony",
	"took",
	"tool",
	"toon",
	"toot",
	"topi",
	"topo",
	"tops",
	"torc",
	"tore",
	"torn",
	"torr",
	"tort",
	"tory",
	"toss",
	"tote",
	"tots",
	"tour",
	"tout",
	"town",
	"tows",
	"toys",
	"tram",
	"trap",
	"tray",
	"tree",
	"trek",
	"trey",
	"trie",
	"trig",
	"trim",
	"trio",
	"trip",
	"trod",
	"trot",
	"true",
	"tsar",
	"tuba",
	"tube",
	"tubs",
	"tuck",
	"tufa",
	"tuff",
	"tuft",
	"tugs",
	"tule",
	"tums",
	"tuna",
	"tune",
	"turd",
	"turf",
	"turk",
	"turn",
	"tush",
	"tusk",
	"tuts",
	"tutu",
	"twee",
	"twig",
	"twin",
	"twit",
	"twos",
	"tyke",
	"type",
	"typo",
	"tyre",
	"tyro",
	"udon",
	"ugly",
	"ulna",
	"umma",
	"umps",
	"undo",
	"unit",
	"unto",
	"updo",
	"upon",
	"urea",
	"urge",
	"uric",
	"urns",
	"used",
	"user",
	"uses",
	"vacs",
	"vail",
	"vain",
	"vale",
	"vamp",
	"vane",
	"vans",
	"vary",
	"vasa",
	"vase",
	"vast",
	"vats",
	"veal",
	"veep",
	"veer",
	"vees",
	"veil",
	"vein",
	"veld",
	"vena",
	"vend",
	"vent",
	"verb",
	"vert",
	"very",
	"vest",
	"veto",
	"vets",
	"vial",
	"vibe",
	"vice",
	"vids",
	"vied",
	"vies",
	"view",
	"vile",
	"vill",
	"vims",
	"vine",
	"vino",
	"viol",
	"visa",
	"vise",
	"vita",
	"viva",
	"void",
	"vole",
	"volt",
	"vote",
	"vows",
	"wack",
	"wade",
	"wadi",
	"wads",
	"waft",
	"wage",
	"wags",
	"waif",
	"wail",
	"wait",
	"wake",
	"wale",
	"wali",
	"walk",
	"wall",
	"wand",
	"wane",
	"wans",
	"want",
	"ward",
	"ware",
	"warm",
	"warn",
	"warp",
	"wars",
	"wart",
	"wary",
	"wash",
	"wasp",
	"wast",
	"watt",
	"wave",
	"wavy",
	"waxy",
	"ways",
	"weak",
	"weal",
	"wean",
	"wear",
	"webs",
	"weds",
	"weed",
	"week",
	"weel",
	"ween",
	"weep",
	"wees",
	"weft",
	"weld",
	"well",
	"welt",
	"wend",
	"went",
	"were",
	"west",
	"wets",
	"what",
	"wham",
	"when",
	"whim",
	"whip",
	"whir",
	"whit",
	"whet",
	"whom",
	"wide",
	"wick",
	"wife",
	"wile",
	"wild",
	"wilt",
	"will",
	"wily",
	"wind",
	"wine",
	"wing",
	"wink",
	"wins",
	"wipe",
	"wire",
	"wise",
	"wish",
	"wisp",
	"with",
	"woad",
	"woes",
	"woke",
	"wolf",
	"womb",
	"wood",
	"woof",
	"wool",
	"woos",
	"word",
	"wore",
	"work",
	"worm",
	"worn",
	"wrap",
	"wren",
	"writ",
	"yaks",
	"yams",
	"yang",
	"yank",
	"yard",
	"yarn",
	"yawn",
	"yeah",
	"year",
	"yell",
	"yoga",
	"yogi",
	"yoke",
	"yore",
	"your",
	"zafu",
	"zags",
	"zany",
	"zarf",
	"zaps",
	"zeal",
	"zero",
	"zinc",
	"zips",
	"zits",
	"zone",
	"zoom",
]

export function getCurrentDictionary() {
	return SADE_LIAM_EASTER_EGG_IS_LIVE ? normal_dictionary.concat(["sade", "liam"]) : normal_dictionary;
}

export var word_combos_i_like = [
	["acid", "rain"],
	["acts", "lies"],
	["ages", "grad"],
	["aide", "hurt"],
	["aloe", "brew"],
	["arid", "cold"],
	["calm", "wind"],
	["fish", "bear"],
	["crab", "seal"],
	["head", "foot"],
	["goat", "lion"],
	["bird", "duck"],
	["rain", "pour"],
	["atom", "bomb"],
	["ride", "boat"],
	["kick", "flop"],
	["dart", "play"],
	["fish", "lion"],
	["mole", "seal"],
	["frog", "puma"],
	["card", "roll"],
	["lamb", "beef"],
	["bird", "swan"],
	["date", "bowl"],
	["duck", "lion"],
	["date", "pear"],
	["deer", "duck"],
	["read", "tone"],
	["duck", "frog"],
	["fish", "swan"],
	["pour", "cold"],
	["bear", "crab"],
	["acid", "base"],
	["star", "glow"],
	["bear", "ibis"],
	["swim", "boat"],
	["pool", "free"],
	["lion", "worm"],
	["fish", "food"],
	["ibis", "dove"],
	["frog", "lion"],
	["hail", "pour"],
	["lion", "wolf"],
	["play", "golf"],
	["push", "pull"],
	["cool", "warm"],
	["redo", "pass"], // now 4
	["foot", "eyes"],
	["runs", "fast"],
	["head", "nose"],
	["ryes", "brew"],
	["disc", "shot"],
	["chin", "pull"],
	["moth", "seal"],
	["bell", "ding"],
	["deer", "fish"],
	["beer", "hops"],
	["duck", "goat"],
	["hand", "eyes"],
	["moth", "puma"],
	["lion", "swan"],
	["laps", "road"],
	["bell", "ring"],
	["frog", "hare"],
	["kick", "ball"],
	["cook", "bake"],
	["deer", "swan"],
	["bear", "lion"],
	["spin", "fast"],
	["frog", "goat"],
	["foot", "feet"],
	["copy", "file"],
	["hare", "newt"],
	["goal", "ball"],
	["food", "beef"],
	["pond", "duck"],
	["toes", "hair"],
	["plum", "bowl"],
	["free", "dive"],
	["cast", "rich"],
	["slow", "mile"],
	["dill", "leek"],
	["swan", "worm"],
	["real", "eyes"],
	["real", "lies"],
	["bear", "puma"],
	["ibis", "newt"],
	["chat", "ring"],
	["runs", "slow"],
	["fire", "calm"],
	["acts", "cast"],
	["soil", "stem"],
	["rain", "calm"],
	["deer", "goat"],
	["swim", "ride"],
	["soft", "easy"],
	["call", "ring"],
	["mend", "hurt"],
	["read", "chat"],
	["reel", "food"],
	["frog", "worm"],
	["corn", "leek"],
	["pool", "bike"],
	["ryes", "cold"],
	["corn", "kale"],
	["lion", "newt"],
	["ears", "hair"],
	["crab", "puma"],
	["bear", "wasp"],
	["golf", "club"],
	["foot", "hair"],
	["play", "roll"],
	["pour", "kegs"],
	["file", "stud"],
	["kegs", "cold"],
	["blue", "pink"],
	["crab", "fish"],
	["eyes", "hair"],
	["tuna", "food"],
	["nail", "stud"],
	["call", "text"],
	["ibis", "lion"],
	["goal", "card"],
	["deer", "wolf"],
	["hair", "chin"],
	["lice", "chin"],
	["role", "play"],
	["pour", "brew"],
	["lime", "pear"],
	["solo", "song"],
	["pool", "dart"],
	["shot", "club"],
	["swim", "free"],
	["meat", "lamb"],
	["wasp", "wolf"],
	["flop", "ball"],
	["bird", "mole"],
	["puma", "seal"],
	["pour", "calm"],
	["hare", "puma"],
	["ears", "nose"],
	["redo", "fail"],
	["loco", "sane"],
	["crab", "ibis"],
	["play", "hole"],
	["beer", "wine"],
	["taco", "meat"],
	["slow", "poke"],
	["zits", "skin"],
	["fish", "hook"],
	["lion", "mule"],
	["lion", "moth"],
	["ales", "hops"],
	["hang", "shoe"],
	["crab", "duck"],
	["ibis", "worm"],
	["duck", "wasp"],
	["crab", "moth"],
	["seal", "worm"],
	["frog", "wolf"],
	["bird", "mule"],
	["chat", "tone"],
	["newt", "wolf"],
	["page", "leaf"],
	["goat", "swan"],
	["home", "sick"],
	["lily", "rose"],
	["hare", "lion"],
	["duck", "wolf"],
	["free", "love"],
	["grub", "beef"],
	["hard", "easy"],
	["pear", "bowl"],
	["swim", "bike"],
	["body", "soul"],
	["grip", "beta"],
	["real", "fake"],
	["free", "cups"],
	["grip", "jump"],
	["frog", "seal"],
	["call", "chat"],
	["fish", "puma"],
	["golf", "putt"],
	["flat", "tire"],
	["newt", "puma"],
	["bird", "ibis"],
	["hare", "wolf"],
	["role", "lies"],
	["goof", "ball"],
	["mule", "puma"],
	["wind", "pour"],
	["gold", "rich"],
	["beer", "brew"],
	["bird", "crab"],
	["mule", "seal"],
	["thin", "mint"],
	["goal", "kick"],
	["ball", "hits"],
	["beer", "ales"],
	["hail", "wind"],
	["ibis", "swan"],
	["fire", "pour"],
	["seal", "wolf"],
	["puma", "swan"],
	["lion", "seal"],
	["disc", "golf"],
	["rugs", "moth"],
	["swim", "pool"],
	["jump", "feet"],
	["fish", "ibis"],
	["moth", "swan"],
	["book", "page"],
	["pool", "lane"],
	["fish", "goat"],
	["fool", "joke"],
	["ride", "road"],
	["fish", "hare"],
	["reel", "tank"],
	["mole", "swan"],
	["jump", "hang"],
	["band", "aria"],
	["pool", "card"],
	["snow", "fire"],
	["kale", "leek"],
	["crab", "wolf"],
	["make", "food"],
	["papa", "bear"],
	["foot", "beta"],
	["puma", "worm"],
	["jump", "ball"],
	["grip", "wall"],
	["slam", "door"],
	["pool", "dice"],
	["ball", "outs"],
	["mule", "swan"],
	["sock", "shoe"],
	["iris", "sage"],
	["hole", "club"],
	["swim", "road"],
	["pear", "plum"],
	["meat", "grub"],
	["frog", "swan"],
	["eyes", "nose"],
	["crab", "mole"],
	["frog", "ibis"],
	["moth", "wolf"],
	["food", "grub"],
	["lily", "sage"],
	["tree", "root"],
	["pool", "dive"],
	["bird", "fish"],
	["frog", "moth"],
	["grip", "shoe"],
	["flop", "card"],
	["swan", "dive"],
	["lion", "mole"],
	["crab", "worm"],
	["mule", "wolf"],
	["foot", "knee"],
	["beef", "tofu"],
	["play", "putt"],
	["free", "fall"],
	["cole", "slaw"],
	["bird", "dove"],
	["duck", "mole"],
	["feet", "hair"],
	["hold", "shoe"],
	["baby", "doll"],
	["bike", "road"],
	["ibis", "mole"],
	["head", "ears"],
	["pool", "ride"],
	["snow", "pour"],
	["fall", "cups"],
	["jump", "beta"],
	["bear", "moth"],
	["cast", "poor"],
	["snow", "wind"],
	["play", "lies"],
	["food", "lamb"],
	["shoe", "belt"],
	["left", "duck"],
	["town", "city"],
	["card", "play"],
	["corn", "dill"],
	["dill", "taro"],
	["solo", "team"],
	["king", "coup"],
	["cold", "brew"],
	["pawn", "king"],
	["lion", "wasp"],
	["door", "knob"],
	["crab", "goon"],
	["disc", "club"],
	["race", "flag"],
	["copy", "fail"],
	["tuna", "tank"],
	["moon", "star"],
	["iris", "lily"],
	["frog", "newt"],
	["ales", "cold"],
	["chat", "bell"],
	["polo", "club"],
	["foot", "ears"],
	["crab", "lion"],
	["feet", "ears"],
	["bite", "nail"],
	["deer", "puma"],
	["fish", "mole"],
	["leaf", "fall"],
	["duck", "puma"],
	["shot", "putt"],
	["kegs", "brew"],
	["wall", "beta"],
	["bird", "deer"],
	["left", "dock"],
	["nose", "hair"],
	["crab", "newt"],
	["grip", "lift"],
	["bird", "puma"],
	["hurt", "pain"],
	["bear", "worm"],
	["solo", "cups"],
	["yell", "moan"],
	["read", "call"],
	["fish", "seal"],
	["book", "leaf"],
	["swim", "laps"],
	["mole", "newt"],
	["laps", "bike"],
	["jogs", "slow"],
	["hold", "hang"],
	["home", "brew"],
	["ball", "cube"],
	["hair", "lice"],
	["soil", "tree"],
	["hang", "beta"],
	["goal", "flop"],
	["hoop", "ball"],
	["duck", "seal"],
	["page", "read"],
	["long", "shot"],
	["hare", "ibis"],
	["flag", "laps"],
	["bear", "wolf"],
	["boat", "dock"],
	["fish", "wolf"],
	["deer", "ibis"],
	["fish", "frog"],
	["leek", "taro"],
	["frog", "mole"],
	["crab", "mule"],
	["hang", "lift"],
	["hand", "foot"],
	["rain", "wind"],
	["fish", "worm"],
	["vast", "huge"],
	["beta", "shoe"],
	["tuna", "fish"],
	["kegs", "ales"],
	["mole", "puma"],
	["lies", "cast"],
	["news", "cast"],
	["snow", "hail"],
	["wall", "shoe"],
	["frog", "mule"],
	["bird", "wolf"],
	["jogs", "fast"],
	["crab", "deer"],
	["door", "jamb"],
	["boat", "duck"],
	["beer", "cold"],
	["chat", "ding"],
	["cows", "milk"],
	["frog", "toad"],
	["free", "ride"],
	["lamb", "tofu"],
	["root", "stem"],
	["bird", "goat"],
	["bear", "hare"],
	["fall", "down"],
	["hops", "brew"],
	["ibis", "wasp"],
	["seal", "wasp"],
	["pool", "game"],
	["lice", "head"],
	["snow", "rain"],
	["jogs", "mile"],
	["book", "club"],
	["bear", "mole"],
	["lies", "fact"],
	["duck", "newt"],
	["tree", "bark"],
	["fire", "hail"],
	["vast", "tiny"],
	["atom", "cell"],
	["hide", "seek"],
	["bike", "seat"],
	["free", "bike"],
	["jail", "free"],
	["lime", "plum"],
	["fire", "rain"],
	["head", "chin"],
	["ball", "mitt"],  // now 4
	["jump", "skip"],
	["card", "foul"],
	["swan", "wasp"],
	["ibis", "seal"],
	["goat", "wasp"],
	["eyes", "ears"],
	["role", "acts"],
	["swim", "dive"],
	["laps", "free"],
	["dusk", "dawn"],
	["play", "cast"],
	["legs", "hair"],
	["moth", "ball"],
	["hole", "putt"],
	["soul", "mate"],
	["deer", "mole"],
	["wall", "jump"],
	["food", "bake"],
	["play", "disc"],
	["palm", "tree"],
	["seed", "dill"],
	["deer", "mule"],
	["free", "solo"],
	["lion", "puma"],
	["nail", "lips"],
	["port", "lake"],
	["flag", "turn"],
	["fish", "reel"],
	["dock", "pond"],
	["ibis", "wolf"],
	["lift", "shoe"],
	["tank", "food"],
	["bird", "newt"],
	["fish", "tank"],
	["reel", "pole"],
	["duck", "moth"],
	["life", "boat"],
	["boat", "dive"],
	["hang", "feet"],
	["hold", "grip"],
	["bear", "frog"],
	["tree", "stem"],
	["heal", "pain"],
	["door", "stop"],
	["copy", "redo"],
	["deer", "hare"],
	["pour", "ales"],
	["dart", "roll"],
	["chin", "band"],
	["pull", "lift"],
	["grip", "foot"],
	["jump", "shoe"],
	["left", "pond"],
	["jump", "foot"],
	["dish", "bowl"],
	["food", "tofu"],
	["wren", "dove"],
	["lift", "beta"],
	["true", "lies"],
	["wolf", "pack"],
	["road", "dive"],
	["post", "mail"],
	["bird", "frog"],
	["hold", "beta"],
	["hang", "foot"],
	["grip", "hang"],
	["game", "play"],
	["bath", "soap"],
	["file", "redo"],
	["puma", "wasp"],
	["mule", "wasp"],
	["full", "moon"],
	["deer", "frog"],
	["bird", "seal"],
	["wall", "foot"],
	["newt", "worm"],
	["deer", "moth"],
	["solo", "aria"],
	["iris", "rose"],
	["hand", "nose"],
	["goat", "ibis"],
	["head", "eyes"],
	["tiny", "huge"],
	["yell", "loud"],
	["leaf", "mark"],
	["putt", "club"],
	["feet", "nose"],
	["pull", "legs"],
	["frog", "wasp"],
	["crab", "hare"],
	["kick", "card"],
	["fish", "mule"],
	["puma", "wolf"],
	["jump", "lift"],
	["golf", "shot"],
	["vein", "flow"],
	["rich", "poor"],
	["sock", "belt"],
	["crab", "swan"],
	["slow", "down"],
	["sage", "dill"],
	["bear", "mule"],
	["deer", "wasp"],
	["lime", "bowl"],
	["monk", "pray"],
	["wood", "camp"],
	["slip", "fall"],
	["goat", "hare"],
	["hare", "swan"],
	["ibis", "moth"],
	["boat", "lake"],
	["soft", "hard"],
	["swan", "wolf"],
	["disc", "hole"],
	["wall", "hang"],
	["feet", "shoe"],
	["mole", "wasp"],
	["call", "ding"],
	["hand", "toes"],
	["hide", "eggs"],
	["snow", "calm"],
	["soul", "mind"],
	["hold", "jump"],
	["laps", "dive"],
	["date", "plum"],
	["read", "mark"],
	["disc", "putt"],
	["mule", "newt"],
	["crab", "wasp"],
	["moth", "newt"],
	["wipe", "butt"],
	["dice", "play"],
	["mend", "pain"],
	["goat", "puma"],
	["free", "road"],
	["duck", "swan"],
	["film", "noir"],
	["hand", "feet"],
	["bird", "lion"],
	["bird", "moth"],
	["fast", "slow"],
	["hold", "fast"],
	["ales", "brew"],
	["hare", "seal"],
	["make", "cook"],
]

// function test_EveryWordIsInDictionary() {
// 	console.log("begin test_EveryWordIsInDictionary run.");
//   var counter = 0;
//   word_combos_i_like.forEach(word_combo => {
//     var start = word_combo[0];
//     var end = word_combo[1];
//     if (!getCurrentDictionary().includes(start)) {
//       counter += 1;
//       console.log("ERROR: " + start + " not in dictionary");
//     }

//     if (!getCurrentDictionary().includes(end)) {
//       counter += 1;
//       console.log("ERROR: " + end + " not in dictionary");
//     }
//   });
//   console.log("test_EveryWordIsInDictionary run is complete.");
//   console.log(counter + " errors found.");
// }

// function test_NoRepeatWordCombos() {
//   console.log("begin test_NoRepeatWordCombos run.");
//   var counter = 0;
//   var comboSet = new Set();
//   word_combos_i_like.forEach(word_combo => {
//     var start = word_combo[0];
//     var end = word_combo[1];
//     var key;
//     if (start >= end) {
//       key = start + end;
//     } else {
//       key = end + start;
//     }
//     if (comboSet.has(key)) {
//       console.log("ERROR: " + key + " already exists");
//       counter += 1;
//     } else {
//       comboSet.add(key);
//     }
//   });

//   console.log("test_NoRepeatWordCombos run is complete.");
//   console.log(counter + " errors found.");
// }

// test_EveryWordIsInDictionary();
// test_NoRepeatWordCombos();
