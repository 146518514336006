import React from 'react';
import "./modals.css";

import { WordContainer } from '../words/words'
import { getSimilarityToGoalClass } from '../utils/utils'

export class HowToPlayModal extends React.Component {
  render() {
    return (
      <div className="modal-container">
        <div className="modal-background" onClick={() => this.props.closeModal()}></div>
        <div className="modal-text how-to-play-text">
          <span className="modal-exit" onClick={() => this.props.closeModal()}>&times;</span>
          <h3>Welcome to Fourword</h3>
          <span>Navigate to the goal word</span>
          <WordContainer 
            word={this.props.goalWord} 
            similarityToGoalClass={getSimilarityToGoalClass(this.props.goalWord, this.props.goalWord)}
            wordIsActive={false}
            gameIsLive={false}
            changedLetterIndex={-1}
            selectedLetterIndex={-1}
            onClick={() => {}}
            onNewWord={() => {}}
          />
          <span>from the starting word</span>
          <WordContainer 
            word={this.props.startingWord} 
            similarityToGoalClass={getSimilarityToGoalClass(this.props.startingWord, this.props.goalWord)}
            wordIsActive={false}
            gameIsLive={false}
            changedLetterIndex={-1}
            selectedLetterIndex={-1}
            onClick={() => {}}
            onNewWord={() => {}}
          />
          {/* <span>by changing a single letter at a time,<br></br>creating words along the way.</span> */}
          <span>by changing a single letter at a time.</span>
          <span>You can only change letters if the result is a valid word.</span>
          <br></br>
          <span>Complete the daily puzzle to earn a color code.</span>
          {/* <span>Your code is determined by the letters you choose,<br></br>and the order in which you choose them.</span>
          <span>If two people get the same code, there's a good chance<br></br>that they solved it the same way!</span> */}
          {/* <div className="modal-section-separator"></div> */}
          <span>The first row of your code represents the columns of the letters you changed.</span>
          <span>The second row of your code shows how helpful each move was.</span>
          {/* <span>The second row of your code shows whether you stepped closer to the goal, stayed the same distance from the goal, or got further from the goal.</span> */}
          <br></br>
          <span>Want to make your own Fourword game?</span>
          <span>Now you can, at <a href="https://www.fourword.xyz#builder" target="_blank" rel="noopener noreferrer">fourword.xyz#builder</a></span>
        </div>
      </div>
    );
  }
}