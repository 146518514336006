import React from 'react';
import "./modals.css";

import { /*getShareableSolutionFromLetters,*/ getShareableSolutionFromColumns } from '../utils/utils'
import {SADE_LIAM_EASTER_EGG_IS_LIVE} from '../../App'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faUndo, faCopy } from '@fortawesome/free-solid-svg-icons'

export class StatsModal extends React.Component {

  copyCode(textToShare) {
    navigator.clipboard.writeText(textToShare)
    this.props.openCopiedToast();
  }

  handleSharing = async (textToShare) => {
    if (navigator.share) {
      try {
        await navigator
          .share({
            title: 'Fourword Daily Code',
            text: textToShare
          });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      this.copyCode(textToShare);
    }
  };
  
  render() {
    var shareableSolution = getShareableSolutionFromColumns(this.props.wordLadder, this.props.indexMapping);
    var textToShare = "I solved fourword.xyz #" + (this.props.dailyWordIndex) + " in " + (this.props.wordLadder.length - 1) + " steps" + (this.props.isSetToHardMode ? " on hard mode " : " ") + "with code\n" + shareableSolution + "\n" + this.props.optimalPathShareableSolution; 
    
    return (
      <div className="modal-container">
        <div className="modal-background" onClick={() => this.props.closeModal()}></div>
        <div className="modal-text">
          <span className="modal-exit" onClick={() => this.props.closeModal()}>&times;</span>
          {!this.props.gameIsLive ? 
            SADE_LIAM_EASTER_EGG_IS_LIVE ?
              <div><h2><a href='https://www.tiktok.com/@juiceboxinatux/video/7079824080899165483?_t=8R1ZmeCoxpr&_r=1' target="_blank" rel="noopener noreferrer">Click me!</a></h2></div>
            : <div>
              <h2>Congratulations!</h2>
              <span>You solved the Fourword in {(this.props.wordLadder.length - 1)} steps.</span>
              {this.props.showMinimumSolve ? 
                <div>
                  <span>Today's minimum solve is {this.props.minimumSolveLength} steps.</span>
                  {this.props.minimumSolveLength === this.props.wordLadder.length - 1 ?
                    <span><br></br>You found an optimal solution! Great job!!</span> :
                    this.props.minimumSolveLength === this.props.wordLadder.length - 2 ? 
                      <span><br></br>Only one step away from optimal, well done!</span> : ""
                  }
                </div> : ""
              }
            </div> : ""
          }
          
          {(this.props.isEasterEggGame) ? "" :
            <Statistics 
              completedValue = {this.props.completedValue}
              optimalPercentageValue = {this.props.optimalPercentageValue}
              currentStreakValue = {this.props.currentStreakValue}
              currentOptimalStreakValue = {this.props.currentOptimalStreakValue}
              maxStreakValue = {this.props.maxStreakValue}
              maxOptimalStreakValue = {this.props.maxOptimalStreakValue}
              numberOfSolutionsTodayValue = {this.props.numberOfSolutionsTodayValue}
              totalSolutionsValue = {this.props.totalSolutionsValue}
            /> 
          }

          {!this.props.gameIsLive && (!this.props.isEasterEggGame) ?
            <div>
              {/* <br></br> */}
              <div id="stats-footer">
                <div>
                  <button className="restart-button button"
                          onClick={() => {
                            this.props.restartGame();
                          }}>
                    Restart <FontAwesomeIcon icon={faUndo} />
                  </button>
                </div>
                <div className="vertical-separator"></div>
                {navigator.canShare ? 
                  <div className="column">
                    <button className="share-button button" 
                            onClick={() => {
                              this.handleSharing(textToShare);
                            }}>
                      Share <FontAwesomeIcon icon={faShareAlt} />
                    </button>
                    <button className="copy-button button" 
                          onClick={() => {
                            this.copyCode(textToShare);
                          }}>
                    Copy <FontAwesomeIcon icon={faCopy} />
                  </button>
                  </div> :
                  <button className="copy-button button" 
                          onClick={() => {
                            this.copyCode(textToShare);
                          }}>
                    Copy <FontAwesomeIcon icon={faCopy} />
                  </button>
                }
              </div>
            </div> : ""
          }

          <div className='pyramini-ad'>
            Like fourword.xyz? Try my new game: <a href="https://pyramini.com" target="_blank" rel="noopener noreferrer">pyramini.com</a>
          </div>
        </div>
      </div>
    );
  }
}

class Statistics extends React.Component {
  // completed, optimal percentage, current streak, optimal streak,
  // max streak, max optimal streak, unique solutions today, max unique solutions in a day,

  render() {
    return (
      <div id="stats-container">
        <h3>Statistics</h3>
        <div id="top-row" className="stat-row">
          <Statistic 
            value={this.props.completedValue}
            label={this.props.completedValue === 1 ? "Fourword Solved" : "Fourwords Solved"}
          />
          <Statistic 
            value={Math.round(this.props.optimalPercentageValue * 100)}
            label={"Optimal Percentage"}
          />
          <Statistic 
            value={this.props.numberOfSolutionsTodayValue}
            label={this.props.numberOfSolutionsTodayValue === 1 ? "Solution Today" : "Solutions Today"}
          />
          <Statistic 
            value={this.props.totalSolutionsValue}
            label={this.props.totalSolutionsValue === 1 ? "Total Solution" : "Total Solutions"}
          />
        </div>
        <div id="bottom-row" className="stat-row">
          <Statistic 
            value={this.props.currentStreakValue}
            label={"Solution Streak"}
          />
          <Statistic 
            value={this.props.currentOptimalStreakValue}
            label={"Optimal Streak"}
          />
          <Statistic 
            value={this.props.maxStreakValue}
            label={"Best Streak"}
          />
          <Statistic 
            value={this.props.maxOptimalStreakValue}
            label={"Best Optimal Streak"}
          />
        </div>
      </div>
    );
  }
}

class Statistic extends React.Component {
  render() {
    return (
      <div className="statistic">
        <div className="value">
          {this.props.value}
        </div>
        <div className="label">
          {this.props.label}
        </div>
      </div>
    );
  }
}