import './fourword_builder.css';
import React from 'react';
import Select from 'react-select';

import { getCurrentDictionary } from '../utils/dictionary';

class FourwordBuilder extends React.Component {
  constructor(props) {
		super(props);
		this.wrapper = React.createRef();

		this.state = {
			firstWord: null,
			secondWord: null,
		}
	}
  
  validWords = getCurrentDictionary().map((word, index) => ({label: word, value: index }));

  handleSelectFirstOptionChange = (selectedOption) => {
    this.setState({
			firstWord: selectedOption.label
		})
  }

  handleSelectSecondOptionChange = (selectedOption) => {
    this.setState({
			secondWord: selectedOption.label
		})
  }

  openNewFourword = () => {
    window.open("https://www.fourword.xyz#" + this.state.firstWord + "-" + this.state.secondWord);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header with-padding">
          <h2 className="fourword-builder-header">Fourword Builder</h2>

          <div className="container">
            <div className="builder-row">
              <div className="col-md-4">
                Create a Fourword from
              </div>
            </div>

            <div className="builder-row word-dropdown">
              <div className="col-md-4">
                <Select 
                  onChange={this.handleSelectFirstOptionChange}
                  options={ this.validWords } />
              </div>
            </div>

            <div className="builder-row">
              <div className="col-md-4">
                to
              </div>
            </div>
            
            <div className="builder-row word-dropdown">
              <div className="col-md-4">
                <Select 
                  onChange={this.handleSelectSecondOptionChange}
                  options={ this.validWords } />
              </div>
            </div>

            <div className="builder-row">
              <div className="col-md-4">
                <button type="button" className="btn btn-primary" onClick={this.openNewFourword} disabled={!(this.state["firstWord"] && this.state["secondWord"])}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
    ); 
  }
}

export default FourwordBuilder;