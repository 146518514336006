import React from 'react';
import "./keyboard.css";

var keyboardRowsWithDownButton = ["qwertyuiop".split(""), "asdfghjkl".split(""), "zxcvbnm".split("").concat(["down"])]
var keyboardRowsWithoutDownButton = ["qwertyuiop".split(""), "asdfghjkl".split(""), "zxcvbnm".split("")]

export class Keyboard extends React.Component {
  render() {
    return (
      <div id="keyboard-container">
        <div id="keyboard-invisible-border"></div>
        {(this.props.isDownButtonVisible ? keyboardRowsWithDownButton : keyboardRowsWithoutDownButton).map((row, i) => 
          <div id={"keyboard-row-" + i} className="keyboard-row" key={row}>
            {row.map((letter, key) => 
              <KeyboardKey 
                value={letter} 
                key={key}
                onClick={() => {
                  this.props.onClick(letter);
                }} />
            )}
          </div>
        )}
      </div>
    );
  }
}

class KeyboardKey extends React.Component {
  render() {
    return (
      <div 
        className={this.props.value !== "down" ? "keyboard-key" : "keyboard-key down-key"} 
        value={this.props.value}
        onClick={this.props.onClick}>
        {this.props.value.toUpperCase()}
      </div>
    );
  }
}